import GamingTab from "./GamingTab";
import CRTTab from "../CRT/CRTTab";
import CCUTab from "../CCU/CCUTab";
import React from "react";

interface Props {
    isGamingUploadEnabled: boolean;
    setGamingDownloadStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GamingTabContents: React.FC<Props> = ({
    setGamingDownloadStatus,
    isGamingUploadEnabled,
}) => {
    return (
        <>
            <GamingTab
                setGamingDownloadStatus={setGamingDownloadStatus}
                isGamingUploadEnabled={isGamingUploadEnabled}
            />
            <CRTTab />
            <CCUTab />
        </>
    );
};

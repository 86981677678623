import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { CashupSubLocationItem } from "../../Redux/StateSlices/GroupData/VenuesAPI";
import { Space } from "antd";
import React, { useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { ATMPanel } from "./ATMPanel";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";

const ATMTab = () => {
    const { venueId, formattedDate, shiftStatus } = useVenueSelection();
    const { data: atmLocations, isLoading: atmLocationsIsLoading } = useQuery(
        ["collapsableATMLocations", venueId, formattedDate],
        () => getLocationsQuery([7, 8], venueId, formattedDate)
    );

    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (atmLocationsIsLoading) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {atmLocations
                .filter(
                    (atmLocation: ExtendedLocationItemWithChildren) =>
                        atmLocation.location_type === 8
                )
                .map(
                    (
                        currentLocation: ExtendedLocationItemWithChildren,
                        index: number
                    ) => {
                        const panelId = `${currentLocation.location_id}-${formattedDate}-${index}`;
                        return (
                            <CollapsibleTable
                                name={currentLocation.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <ATMPanel
                                    key={currentLocation.name}
                                    name={currentLocation.name}
                                    // @ts-ignore
                                    cashupIds={currentLocation.cashup_ids}
                                    subLocations={
                                        currentLocation.sub_locations as unknown as CashupSubLocationItem[]
                                    }
                                    locations={atmLocations}
                                    transferLocations={atmLocations}
                                    onClickHandler={(
                                        tableIndex: number | undefined
                                    ) =>
                                        setCurrenTableInFocus?.(
                                            `${panelId}-${tableIndex}`
                                        )
                                    }
                                    currentTableInFocus={currentTableInFocus}
                                    panelId={panelId}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default ATMTab;

import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { GamingPanel } from "./GamingPanel";
import { GamingLocation } from "../../Redux/StateSlices/GroupData/VenuesAPI";
import { Space } from "antd";
import React, { useMemo, useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { getVenueById } from "../../ApiV2/Helpers/getVenueById";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import dayjs from "dayjs";

interface Props {
    setGamingDownloadStatus: React.Dispatch<React.SetStateAction<boolean>>;
    isGamingUploadEnabled: boolean;
}

const GamingTab: React.FC<Props> = ({
    setGamingDownloadStatus,
    isGamingUploadEnabled,
}) => {
    const { venueId, formattedDate, shiftStatus, activePanel, setActivePanel } =
        useVenueSelection();
    const {
        data: gamingLocations,
        isLoading: gamingLocationsIsLoading,
        isFetching,
    } = useQuery(["collapsableGamingLocations", venueId, formattedDate], () =>
        getLocationsQuery([2, 7], venueId, formattedDate)
    );

    const { data: venueData, isLoading: venueDataIsLoading } = useQuery(
        ["venueData", venueId, formattedDate],
        () => getVenueById(venueId)
    );
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (venueDataIsLoading || gamingLocationsIsLoading || isFetching) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {gamingLocations
                .filter(
                    (gl: ExtendedLocationItemWithChildren) => gl.location_type === 2
                )
                .map(
                    (
                        currentLocation: ExtendedLocationItemWithChildren,
                        index: number
                    ) => {
                        const panelId = `${currentLocation.location_id}-${formattedDate}-${index}`;
                        return (
                            <CollapsibleTable
                                name={currentLocation.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <GamingPanel
                                    key={currentLocation.location_id}
                                    name={currentLocation.name}
                                    subLocations={
                                        currentLocation.sub_locations as unknown as GamingLocation[]
                                    }
                                    venueData={venueData}
                                    transferLocations={[
                                        ...gamingLocations.filter(
                                            (gl: ExtendedLocationItemWithChildren) =>
                                                gl.location_type === 7
                                        ),
                                        currentLocation,
                                    ]}
                                    onClickHandler={(tableIndex?: number) =>
                                        setActivePanel(`${panelId}-${tableIndex}`)
                                    }
                                    currentTableInFocus={activePanel}
                                    panelId={panelId}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                    isGamingUploadEnabled={isGamingUploadEnabled}
                                    setGamingDownloadStatus={setGamingDownloadStatus}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default GamingTab;

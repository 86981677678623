import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Space } from "StyledComponents/Space";
import {
    CashupDailyReport,
    CashupDailyReportCsv,
} from "./Components/CashupDailyReport/CashupDailyReport";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";

const Container = styled.div`
    min-height: 280px;
    border-radius: 8px;
`;

export const CashupDailyReportContainer: React.FC = () => {
    const { ready, groupData } = useIntializeCashupReport();
    const csvReport = useRef<CashupDailyReportCsv>({});
    const { venueId, shiftDate: date } = useVenueSelection();

    const buildCsvReport = useCallback(
        (
            csvReportItems: Array<{
                section: keyof CashupDailyReportCsv;
                content: string;
            }>
        ) => {
            csvReportItems.forEach(({ content, section }) => {
                csvReport.current[section] = `${content} ,,,\n`;
            });
        },
        [date, venueId, csvReport.current]
    );
    useEffect(() => {
        csvReport.current = {};
    }, [date, venueId]);

    if (!ready) {
        return <QuantacoLoader size={100} />;
    }

    return (
        <Container>
            <Space direction="vertical" $fluid>
                <CashupDailyReport
                    updateCashupReportCsv={buildCsvReport}
                    date={date}
                    venueId={venueId}
                    groupData={groupData}
                />
            </Space>
        </Container>
    );
};

import React, { useCallback, useMemo } from "react";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import produce from "immer";
import { CashupSubLocationItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import {
    countTransactions,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
    transformLocationsToLocationDetailByCashup,
} from "../../utils/utilities";
import { TableRows } from "V2/Table/TableBody";
import set from "lodash/set";
import { Table } from "V2/Table/Table";
import { TableConfigs } from "V2/Table/TableHeader";
import cloneDeep from "lodash/cloneDeep";
import { DenominationsForm } from "../../Pages/CashupHome/ExcelTable/DenominationsForm";
import { AntDFormStateWithoutSplit, KeyValuePair } from "@types";
import { TableDataType } from "../POS/POSPanel";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import { TransactionsFormTransferOnly } from "../../Pages/CashupHome/ExcelTable/TransactionsFormTransferOnly";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { formatDollarField } from "@Constants";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { StyledCollapsedPanel } from "Components/StyledCollapsedPanel";
import Title from "antd/lib/typography/Title";
import { positiveNumberValidator } from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";

interface Props extends PanelHOCComponentProps {
    name: string;
    cashupIds: string[];
    subLocations: CashupSubLocationItem[];
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    submitted: boolean;
    panelId?: string;
    currentTableInFocus?: string;
}

const initializeAtmField = (cashup: InitDataResponseType): InitDataResponseType => {
    const atmData = cashup.atm_data;
    return {
        ...cashup,
        atm_data: {
            refills: atmData?.refills ?? 0,
            withdrawls: atmData?.withdrawls ?? 0,
        },
    };
};

export const CRTPanel: React.FC<Props> = ({
    cashupIds,
    subLocations,
    locations,
    hierarchicalLocations,
    onClickHandler,
    submitted,
    panelId,
    currentTableInFocus,
}) => {
    const { activePanel } = useVenueSelection();

    const [updateCashup] = usePushCashupMutation();
    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);

                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });
    const locationDetailByCashupId = useMemo(
        () => transformLocationsToLocationDetailByCashup(subLocations),
        [subLocations]
    );

    const sortedCashupIDS = useMemo(
        () =>
            cloneDeep(cashupIds).sort((cashupA_ID, cashupB_ID) =>
                sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
            ),
        [cashupIds, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedCashupIDS,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            //  setTableData(tableData);

            handleCashupChanged(tableData[rowIndex]);
        },
        [handleCashupChanged]
    );

    const updateDenominationsState = useCallback(
        (type: "WITHDRAWALS" | "REFILLS", rowIndex: number) =>
            (data: KeyValuePair) => {
                const tableDataDeepCopy = cloneDeep(tableData);

                const denominations = [];
                let denominationsSum = 0;

                for (const [key, value] of Object.entries(data)) {
                    if (value !== undefined) {
                        const dollarValue = Number(
                            typeof value === "number" ? value.toString() : value
                        );
                        const temp: { [name: string]: number } = {};
                        temp[key] = dollarValue;
                        denominations.push(temp);
                        denominationsSum += dollarValue;
                    }
                }

                if (type === "WITHDRAWALS") {
                    tableDataDeepCopy[rowIndex].atm_data = {
                        ...tableDataDeepCopy[rowIndex]?.atm_data,
                        withdrawalsDenominations: denominations,
                        withdrawls: denominationsSum,
                    };
                } else if (type === "REFILLS") {
                    tableDataDeepCopy[rowIndex].atm_data = {
                        ...tableDataDeepCopy[rowIndex]?.atm_data,
                        refillsDenominations: denominations,
                        refills: denominationsSum,
                    };
                }

                onRowDataChange(rowIndex, tableDataDeepCopy);
            },
        [tableData, onRowDataChange]
    );

    const updateCashCountDenominationsState = useCallback(
        (rowIndex: number) => (data: KeyValuePair) => {
            const tableDataDeepCopy = cloneDeep(tableData);

            const denominations = [];
            let denominationsSum = 0;

            for (const [key, value] of Object.entries(data)) {
                if (value !== undefined) {
                    const dollarValue = Number(
                        typeof value === "number" ? value.toString() : value
                    );
                    const temp: { [name: string]: number } = {};
                    temp[key] = dollarValue;
                    denominations.push(temp);
                    denominationsSum += dollarValue;
                }
            }

            tableDataDeepCopy[rowIndex].cash_count = {
                ...tableDataDeepCopy[rowIndex]?.cash_count,
                actualDenominations: denominations,
                actual: formatDollarField(denominationsSum),
            };

            onRowDataChange(rowIndex, tableDataDeepCopy);
        },
        [tableData, onRowDataChange]
    );
    const resetCashCountAndDenominations = (rowIndex: number) => {
        const tableDataDeepCopy = cloneDeep(tableData);

        tableDataDeepCopy[rowIndex].cash_count.actual = undefined;
        tableDataDeepCopy[rowIndex].cash_count.actualDenominations = [];

        onRowDataChange(rowIndex, tableDataDeepCopy);
    };
    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onCRTMachineCountChange = useCallback(
        (
            tableData: InitDataResponseType[],
            tableRow: number,
            property: string,
            data: number
        ) => {
            const tableDataDeepClone = cloneDeep(tableData);
            tableDataDeepClone[tableRow].atm_data = {
                ...tableDataDeepClone[tableRow].atm_data,
                crt_machine_counts: {
                    ...tableDataDeepClone[tableRow].atm_data?.crt_machine_counts,
                    [property]: data,
                },
            };

            onRowDataChange(tableRow, tableDataDeepClone);
        },
        []
    );

    const dataSources: TableRows[] = tableData.map((cashup, rowIndex) => {
        const {
            cashup_id,
            atm_data,
            cash_count,
            transfer_transactions,
            status,
            transfer_transactions_to,
        } = cashup;
        const withdrawalErrorFlag = !atm_data || atm_data?.withdrawls === undefined;
        return [
            {
                value: locationDetailByCashupId[cashup_id].name,
                readOnly: true,
                subValue: parseTimePeriod(
                    locationDetailByCashupId[cashup_id].timePeriod
                ),
            },
            {
                value: cash_count.opening_balance ?? 0,
                readOnly: true,
            },
            {
                value: atm_data?.withdrawls ?? 0,
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "CRT_withdrawals" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateDenominationsState("WITHDRAWALS", rowIndex)}
                        onModalClose={closeModal}
                        initialValues={atm_data?.withdrawalsDenominations ?? []}
                        extendDenominationOptions
                        disabled={submitted}
                    />
                ),
                modalTitle: "Withdrawals",
                errorHighlighting: withdrawalErrorFlag,
                hideEditModalFooter: true,
            },
            {
                value: atm_data?.refills ?? 0,
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "CRT_refills_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateDenominationsState("REFILLS", rowIndex)}
                        onModalClose={closeModal}
                        initialValues={atm_data?.refillsDenominations ?? []}
                        extendDenominationOptions
                        disabled={submitted}
                    />
                ),
                modalTitle: "Refills",
                hideEditModalFooter: true,
            },
            {
                value: tableData[rowIndex]
                    ? countTransactions([
                          transfer_transactions ?? [],
                          transfer_transactions_to ?? [],
                      ])
                    : "",
                editModal: (closeModal) => (
                    <TransactionsFormTransferOnly
                        key={"CRT" + locationDetailByCashupId[cashup_id].location_id}
                        locations={locations}
                        onTransferTransactionSubmission={(data) =>
                            onTransferTransactionSubmissionDebounce(data, rowIndex)
                        }
                        TransferTransactions={transfer_transactions ?? []}
                        TransferTransactionsReadOnly={transfer_transactions_to ?? []}
                        hierarchicalLocations={hierarchicalLocations}
                        currentLocationID={
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onModalClose={closeModal}
                        disabled={submitted}
                    />
                ),
                modalTitle: "Transactions",
                hideEditModalFooter: true,
            },
            {
                value: cash_count.actual ?? "$0",
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "CRT_cc_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateCashCountDenominationsState(rowIndex)}
                        onModalClose={closeModal}
                        initialValues={cash_count?.actualDenominations ?? []}
                        onClear={() => {
                            resetCashCountAndDenominations(rowIndex);
                        }}
                        extendDenominationOptions
                        disabled={submitted}
                    />
                ),
                modalTitle: "Cash Count",
                hideEditModalFooter: true,
            },
            {
                value: formatDollarField(cash_count.expected_cash ?? 0),
                readOnly: true,
                statusField: () =>
                    cash_count.expected_cash === null ? status : null,
            },
            {
                value:
                    typeof cash_count.cash_variance !== "string"
                        ? formatDollarField(cash_count.cash_variance ?? 0)
                        : cash_count.cash_variance,
                readOnly: true,
                statusField: () => status ?? null,
            },
        ];
    });

    const tableConfigs: TableConfigs = [
        { columnTitle: "Name" },
        { columnTitle: "Opening balance" },
        { columnTitle: "CRT Payouts" },
        { columnTitle: "Refills" },
        { columnTitle: "Transactions", hideTotal: true },
        { columnTitle: "CRT Cash count", separateColumn: true },
        { columnTitle: "Expected" },
        { columnTitle: "Variance" },
    ];

    const dataSourceForCRTMachineRead: TableRows[] = useMemo(
        () =>
            tableData.map(({ atm_data }, rowIndex) => {
                const variance =
                    (atm_data?.crt_machine_counts?.cash_count ?? 0) -
                    (atm_data?.crt_machine_counts?.cash_box_read ?? 0);
                return [
                    {
                        value: "",
                        readOnly: true,
                    },
                    {
                        value: atm_data?.crt_machine_counts?.cash_box_read ?? 0,
                        onSubmit: (data) =>
                            onCRTMachineCountChange(
                                tableData,
                                rowIndex,
                                "cash_box_read",
                                data
                            ),
                        readOnly: submitted,
                        validator: positiveNumberValidator,
                    },
                    {
                        value: atm_data?.crt_machine_counts?.cash_count ?? 0,
                        onSubmit: (data) =>
                            onCRTMachineCountChange(
                                tableData,
                                rowIndex,
                                "cash_count",
                                data
                            ),
                        readOnly: submitted,
                        validator: positiveNumberValidator,
                    },
                    {
                        value: variance,
                        readOnly: true,
                    },
                ];
            }),
        [tableData, submitted]
    );

    const tableConfigsForCRTMachineCounts: TableConfigs = [
        { columnTitle: "Cash Box" },
        { columnTitle: "Cash To Cash" },
        { columnTitle: "Cash Count" },
        { columnTitle: "Variance" },
    ];

    if (isLoading || tableData.length === 0) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "-35px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"small"}
            />
        );
    }
    return (
        <>
            <Table
                onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                    const updatedCashup = produce(
                        initializeAtmField(tableData[row]),
                        (draft) => {
                            set(draft, propertyPath, updatedValue);
                        }
                    );
                    handleCashupChanged(updatedCashup);
                }}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={() => onClickHandler(1)}
                isInFocus={activePanel === `${panelId}-1`}
            />
            <StyledCollapsedPanel
                header={<Title level={3} style={{ margin: "0px" }}></Title>}
                style={{ paddingTop: "12px", paddingBottom: "24px" }}
                key={"1"}
            />
            <Table
                dataSources={dataSourceForCRTMachineRead}
                tableConfigs={tableConfigsForCRTMachineCounts}
                onClickHandler={() => onClickHandler(2)}
                isInFocus={activePanel === `${panelId}-2`}
                evenDistribution
            />
        </>
    );
};

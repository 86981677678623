import dayjs from "dayjs";
export const TOP_BANNER_EVENT_NAME = "top-banner-update-event";

export const handleBalancesEvent = (
    data: any,
    setTopBannerValues: React.Dispatch<
        React.SetStateAction<
            | {
                  total_sales: number;
                  cash_increase: number;
                  bankable: number;
              }
            | undefined
        >
    >
) => {
    try {
        const BannerValues: {
            total_sales: number;
            cash_increase: number;
            bankable: number;
        } = {
            total_sales: data["total_sales"],
            cash_increase: data["cash_increase"],
            bankable: data["bankable"],
        };
        window.dispatchEvent(new Event(TOP_BANNER_EVENT_NAME));
        setTopBannerValues(BannerValues);
    } catch (e) {
        console.log("HBE invalid response", data);
        setTopBannerValues(undefined);
    }
};

export const parseDateToDisplay = (
    lastSubmittedDate: string,
    selectedDate: string
) => {
    if (new Date(selectedDate).toString() === "Invalid Date") {
        throw new Error("Invalid Date");
    }
    if (!lastSubmittedDate) {
        return `until\n ${dayjs(selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")}`;
    }

    if (new Date(lastSubmittedDate).getTime() >= new Date(selectedDate).getTime()) {
        return `\n\r${dayjs(selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")}\n\r`;
    } else {
        return `from\n ${dayjs(lastSubmittedDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
        )}\n to\n ${dayjs(selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")}`;
    }
};

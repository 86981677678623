import React, { useMemo, useState } from "react";
import { Row, Space, Col, Typography, Tooltip, Spin } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CashupReportPieChart from "./CashupReportPieChart";
import CashupReportBarChart from "./CashupReportBarChart";
import CashupReportTable from "./CashUpReportTable";
import { ReportItem } from "@interfaces";
import {
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    TableTitle,
    DownloadLoadingContainer,
} from "./CashupReportStyled";
import {
    getAllUniqueKeys,
    columnGenerator,
    totalsForAllLocations,
    prepareBarGraphData,
    sortDataBasedOnKeys,
    downloadPdfReport,
    downloadCsvReport,
    preparePosCsvData,
} from "../utils";

const { Title } = Typography;

interface PieChartProps {
    reportData: ReportItem[];
    areaName: string;
    reportType: string;
    date: { start_date: string; end_date: string };
    venueName: string;
}

const CashupPosClassesTenderReport: React.FC<PieChartProps> = ({
    reportData,
    areaName,
    reportType,
    date,
    venueName,
}) => {
    //sort report data
    const sortedData = useMemo(() => sortDataBasedOnKeys(reportData), [reportData]);
    const [download, setDownload] = useState(false as boolean);

    //prepare data to show totals for all locations
    //extracting unique keys from report data in sorted format
    const allKeys: string[] = useMemo(
        () =>
            sortedData.length > 0
                ? (getAllUniqueKeys(sortedData[0].data) as string[]).sort()
                : [],
        [sortedData]
    );

    //prepare columns
    const columns = useMemo(
        () => columnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (sortedData.length > 0 ? totalsForAllLocations(sortedData) : {}),
        [sortedData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...totals,
    };

    //extract pie chart data from totals
    const pieChartData: number[] = useMemo(() => Object.values(totals), [totals]);

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => prepareBarGraphData(sortedData), [sortedData]);

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${areaName} ${reportType}`;
        await downloadPdfReport(reportName, "pos-report", venueName, date);
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = preparePosCsvData(sortedData, totals);
        const reportName = `${areaName} ${reportType}`;
        downloadCsvReport(reportName, venueName, date, csvData);
        setDownload(false);
    };

    return (
        <>
            <div id="pos-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${areaName} - ${reportType}`}</Title>
                        </Col>
                        <Col>
                            <div style={{ display: "flex", gap: "15px" }}>
                                <Tooltip title="Download PDF">
                                    <FilePdfOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                {/* <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onCSVClick}
                                    />
                                </Tooltip> */}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportBarChart data={barChartData} />
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportPieChart
                                data={pieChartData}
                                labels={allKeys}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[20, 2]}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`Total (${dayjs(
                                    date.start_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")} - ${dayjs(
                                    date.end_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")})`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        rowKey="date"
                                        dataSource={[totalRow]}
                                        columns={columns}
                                        size="small"
                                        scroll={{ x: true }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                    {sortedData.map((item: ReportItem) => (
                        <Row
                            gutter={[20, 2]}
                            key={`${item.location_type} - ${item.parent_location_name}`}
                        >
                            <Col xs={24}>
                                <LocationContainer>
                                    <TableTitle>{`${item.location_type} - ${item.parent_location_name}`}</TableTitle>
                                    <CashupReportTable
                                        data={item.data}
                                        sortBoolean={true}
                                        downloading={download}
                                    />
                                </LocationContainer>
                            </Col>
                        </Row>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupPosClassesTenderReport;

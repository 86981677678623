import { Button, Card, Col, Result, Row, Spin, Statistic, Table } from "antd";
import { chain, Dictionary } from "lodash";
import {
    LocationType,
    ShiftReportPrompt,
} from "Pages/CashupReport/CashupReportModel";
import { useGetCashups } from "Pages/CashupReport/Hooks/useGetCashups";
import { useGetShiftReportPrompts } from "Pages/CashupReport/Hooks/useGetShiftReportPrompts";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useListShiftReportPromptRecords } from "Pages/CashupReport/Hooks/useShiftReportPromptRecord";
import { CenteredSingleDivNoHeight } from "Components/CenteredCard";
import { Parser } from "json2csv";
import { parseLocationCashupsForDailyReportExport } from "Pages/CashupReport/CashupReportUtils";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import styled from "styled-components";
import { gql, useQuery } from "@apollo/client";
import { GroupDataState } from "Redux/StateSlices/GroupData";
import useEmblaCarousel from "embla-carousel-react";
import { GraphQLClient } from "graphql-request";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { CashupDailyReportCommentsNew } from "./CashupDailyReportCommentsNew";
import { NextButton, PrevButton } from "./CarouselArrows";
import { DownloadOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { SalesBreakdown } from "./SalesBreakdown";
import { RenderLocationCard } from "./RenderLocationCard";
import dayjs, { Dayjs } from "dayjs";
import CountUp from "react-countup";
import { Formatter, valueType } from "antd/es/statistic/utils";
import { useIsMobile } from "Pages/CashupHome/CollaborationTable/useIsMobile";
import { TOP_BANNER_EVENT_NAME } from "Pages/CashupHome/CollaborationTable/utils";
import JsPDF, { ImageProperties } from "jspdf";
import html2canvas from "html2canvas";
import { GamingReport } from "../../../../Components/GamingReport/GamingReport";

export type CashupDailyReportCsv = {
    venue?: string;
    date?: string;
    comments?: string;
} & Partial<Record<LocationType, string>>;

const StyledMainCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 98%;

    @media screen and (max-width: 792px) {
        flex-direction: column;
    }
    padding-bottom: 16px;
`;

export const StyledSection = styled.div<{
    shouldHideOnMobile?: boolean;
}>`
    padding: 4px;
    border-radius: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ shouldHideOnMobile }) =>
        shouldHideOnMobile &&
        `
    @media screen and (max-width: 792px) {
        display: none;
    }`}
`;

export const StyledTable = styled(Table)`
    .ant-table {
        border: 1px solid #e8e8e8;
    }
`;

const Container = styled(Card)`
    min-height: 280px;
    padding: 24px;
    border-radius: 8px;

    margin-bottom: 20px;
    flex: 1;

    @media screen and (max-width: 792px) {
        padding: 8px !important;

        .ant-card-body {
            padding: 8px !important;
        }

        .ant-card {
            padding: 8px !important;
        }
    }
`;

const StyledCommentsSection = styled.div`
    border-radius: 8px;
    margin: 8px 24px;

    @media (max-width: 768px) {
        margin: 8px 0px;
    }
`;

const supportedLocationTypes = [
    LocationType.pos,
    LocationType.keno,
    LocationType.tab,
    LocationType.gaming,
];

const csvParser = new Parser({ header: false });

/**
 * Helper method to only append comment section if there are shift prompts present
 * @param currentArray
 * @param commentReport
 * @returns
 */
const appendCommentsToCSV = (
    currentArray: Array<{
        section: keyof CashupDailyReportCsv;
        content: string;
    }>,
    commentReport: {
        prompt: string;
        answer: string;
    }[]
): Array<{
    section: keyof CashupDailyReportCsv;
    content: string;
}> => {
    if (commentReport.length > 0)
        return [
            ...currentArray,
            {
                section: "comments",
                content: csvParser.parse(commentReport),
            },
        ];

    return currentArray;
};

const StyledMobileOnlyArrows = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 0px 24px;
        margin-top: -4px;
    }
`;
const HideOnMobile = styled.div`
    display: initial;
    @media (max-width: 768px) {
        display: none;
    }
`;

const StyledDesktopOnlyFlexBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        display: initial;
    }
`;

const SALES_ALL_LOCATIONS = gql`
    query CubeQuery($venueId: String!, $splitDate: String!, $locations: [String!]) {
        cube(
            where: {
                splits: {
                    splitDate: { equals: $splitDate }
                    locationId: { in: $locations }
                    venueId: { equals: $venueId }
                }
                account: { salesAccount: { equals: "true" } }
            }
        ) {
            splits(orderBy: { sumTotalIncTax: desc }) {
                sumTotalIncTax
                splitDate {
                    value
                }
                classId
            }
            account {
                name
            }
            class {
                name
            }
        }
    }
`;

const PROMO_ACCOUNTS = gql`
    query CubeQuery($splitDate: String!, $venueId: String!) {
        cube(
            where: {
                account: { promoAccount: { equals: "true" } }
                splits: {
                    splitDate: { equals: $splitDate }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { totalIncTax: asc }) {
                totalIncTax
            }
            account {
                promoAccount
                name
            }
        }
    }
`;

const CASH_VARIANCE_FOR_ALL_POS_LOCATIONS_AND_GAMING = gql`
    query CubeQuery($date: String!, $venueId: String!) {
        cube(
            where: {
                account: { cashAccount: { equals: "true" } }
                transactions: { transactionType: { equals: 4 } }
                location: { locationType: { in: [1, 2] } }
                splits: { splitDate: { equals: $date } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

const WAGERING_QUERY = gql`
    query CubeQuery($date: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { in: [3, 9] } }
                cashup: { shiftDate: { equals: $date } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                wageringDataCommission
                cashCountCashVariance
            }
            location {
                name
            }
        }
    }
`;
const GAMING_DATA_QUERY = gql`
    query CubeQuery($venueId: String!, $splitDate: String!) {
        cube(
            limit: 5000
            where: {
                gamingStats: {
                    date: { equals: $splitDate }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            gamingStats(orderBy: { date: asc }) {
                date {
                    value
                }
                turnover
                netProfit
                returnToHouse
                actualProfit
                actualCash
            }
        }
    }
`;

const PETTY_CASH_QUERY = gql`
    query CubeQuery($splitDate: String!, $venueId: String!) {
        cube(
            where: {
                splits: {
                    splitDate: { equals: $splitDate }
                    venueId: { equals: $venueId }
                }
                transactions: { paymentType: { equals: 2 } }
                account: { tenderType: { equals: "true" } }
            }
        ) {
            splits(orderBy: { totalIncTax: asc }) {
                totalIncTax
            }
            account {
                name
            }
        }
    }
`;

const formatter: Formatter = (value: valueType) => {
    if (typeof value === "string") {
        return value;
    }
    return <CountUp end={value} separator="," decimals={2} duration={1} />;
};

export const SectionTitle = ({ title }: { title: string }) => (
    <div
        style={{
            fontWeight: "bold",
            fontSize: 20,
            textAlign: "center",
            paddingBottom: 8,
        }}
    >
        {title}
    </div>
);

const RenderLocationCardsComponent = ({
    locations,
    venueId,
    groupData,
    parsedDate,
    printMode,
}: {
    locations: Dictionary<ExtendedLocationItemWithChildren[]>;
    venueId: string;
    groupData: GroupDataState;
    parsedDate: Dayjs;
    printMode: boolean;
}) => {
    const isMobile = useIsMobile();
    const [queryResults, setQueryResults] = useState<
        {
            locationId: string;
            locationName: string;
            salesTotalSalesData: { [classId: string]: number };
        }[]
    >([]);

    const graphQLClient = new GraphQLClient(
        // TODO HANDLE
        process.env.REACT_APP_CUBEJS_BACKEND_API_URL?.replace(
            "/v1",
            "/graphql"
        ) as string,
        {
            headers: {
                authorization: `Bearer ${groupData.cubejs_token}`,
            },
            cache: "no-store",
        }
    );

    //@ts-ignore
    const [emblaRef, emblaApi] = useEmblaCarousel({
        slidesToScroll: "auto",
        containScroll: "trimSnaps",
    });

    const hasRunOnceRef = useRef(false);

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    );
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    );

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect();
        emblaApi.on("select", onSelect);
        emblaApi.on("reInit", onSelect);
    }, [emblaApi, onSelect]);

    const posLocations = useMemo(() => locations[LocationType.pos], [locations]);
    const runAllQueries = async () => {
        const results: {
            locationId: string;
            locationName: string;
            salesTotalSalesData: { [classId: string]: number };
        }[] = [];
        if (!posLocations) return;

        if (hasRunOnceRef.current === true) return;

        posLocations
            .filter((currentLocation) => {
                return currentLocation.sub_locations.length > 0;
            })
            .map((currentPosLocation) => {
                const salesTotalSalesData: {
                    [classId: string]: number;
                } = {};
                graphQLClient
                    .request(SALES_ALL_LOCATIONS, {
                        venueId,
                        splitDate: parsedDate.format("YYYY-MM-DD"),
                        locations:
                            currentPosLocation.sub_locations.map(
                                (currentLocation) => currentLocation.location_id
                            ) ?? [],
                    })
                    .then((result: any) => {
                        result.cube.forEach((PosEntry: any) => {
                            const match = groupData.classes.find(
                                (currentClass) =>
                                    PosEntry.splits.classId === currentClass.class_id
                            );

                            if (match) {
                                if (salesTotalSalesData[match.class_id]) {
                                    salesTotalSalesData[match.class_id] =
                                        salesTotalSalesData[match.class_id] +
                                        PosEntry.splits.sumTotalIncTax;
                                } else {
                                    salesTotalSalesData[match.class_id] =
                                        PosEntry.splits.sumTotalIncTax;
                                }
                            } else {
                                // TODO ADD SENTRY / BUGSNAG LOG
                                console.log("Unknown class");
                            }
                        });
                        results.push({
                            locationId: currentPosLocation.location_id,
                            locationName: currentPosLocation.name,
                            salesTotalSalesData,
                        });
                    });
            });
        setTimeout(() => {
            setQueryResults(results);
        }, 1000);
        hasRunOnceRef.current = true;
    };

    useEffect(() => {
        runAllQueries();
    }, [posLocations, hasRunOnceRef, graphQLClient]);

    const onTopBannerUpdate = useCallback(() => {
        hasRunOnceRef.current = false;
        runAllQueries();
    }, [runAllQueries]);

    useEffect(() => {
        window.addEventListener(TOP_BANNER_EVENT_NAME, onTopBannerUpdate);
        return () =>
            window.removeEventListener(TOP_BANNER_EVENT_NAME, onTopBannerUpdate);
    }, []);

    const sortedQueryResults = useMemo(() => {
        if (queryResults.length !== posLocations?.length) return [];
        return queryResults.sort((currentQueryResult, nextQueryResult) => {
            let currentTotal = 0;
            let nextTotal = 0;
            Object.keys(currentQueryResult.salesTotalSalesData).forEach(
                (currentClassId) => {
                    currentTotal +=
                        currentQueryResult.salesTotalSalesData[currentClassId];
                }
            );
            Object.keys(nextQueryResult.salesTotalSalesData).forEach(
                (currentClassId) => {
                    nextTotal += nextQueryResult.salesTotalSalesData[currentClassId];
                }
            );
            return nextTotal - currentTotal;
        });
    }, [queryResults, posLocations]);

    if (queryResults.length !== posLocations?.length) return <CentredSpinner />;

    // TODO Parse the query results then use the totals to sort the locations

    if (printMode) {
        return (
            <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 16]}>
                {sortedQueryResults.map((currentPosLocation, index) => {
                    return (
                        <Col
                            key={index}
                            className="gutter-row"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <RenderLocationCard
                                key={index}
                                name={currentPosLocation.locationName}
                                groupData={groupData}
                                salesTotalSalesData={
                                    queryResults[index].salesTotalSalesData
                                }
                                isMobile={isMobile}
                            />
                        </Col>
                    );
                })}
            </Row>
        );
    } else {
        return (
            <StyledDesktopOnlyFlexBox>
                <HideOnMobile>
                    <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                </HideOnMobile>
                <div className="embla">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                            {sortedQueryResults.map((currentPosLocation, index) => {
                                return (
                                    <div className="embla__slide" key={index}>
                                        <RenderLocationCard
                                            key={index}
                                            name={currentPosLocation.locationName}
                                            groupData={groupData}
                                            salesTotalSalesData={
                                                queryResults[index]
                                                    .salesTotalSalesData
                                            }
                                            isMobile={isMobile}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <StyledMobileOnlyArrows>
                    <Button
                        type="primary"
                        icon={<LeftOutlined />}
                        onClick={scrollPrev}
                        disabled={!prevBtnEnabled}
                        size="large"
                    />
                    <Button
                        type="primary"
                        icon={<RightOutlined />}
                        onClick={scrollNext}
                        disabled={!nextBtnEnabled}
                        size="large"
                    />
                </StyledMobileOnlyArrows>

                <HideOnMobile>
                    <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </HideOnMobile>
            </StyledDesktopOnlyFlexBox>
        );
    }
};

const RenderLocationCards = React.memo(RenderLocationCardsComponent);

interface ReportProps {
    date: string;
    venueId: string;
    updateCashupReportCsv: any;
    groupData: GroupDataState | undefined;
}

export const CashupDailyReport: React.FC<ReportProps> = ({
    date,
    venueId,
    updateCashupReportCsv,
    groupData,
}) => {
    const [printMode, setPrintMode] = useState(false);

    const isMobile = useIsMobile();

    const parsedDate = useMemo(() => {
        return dayjs(date, "DD-MM-YYYY");
    }, [date]);

    const allSublocationIds = useMemo(
        () =>
            groupData?.locations_hierarchy
                ?.filter(
                    (currentLocation) =>
                        currentLocation.location_type === LocationType.pos ||
                        currentLocation.location_type === LocationType.gaming
                )
                .reduce((accumulator, currentLocation) => {
                    currentLocation.sub_locations.forEach((currentSubLocation) => {
                        accumulator.push(currentSubLocation.location_id);
                    });
                    return accumulator;
                }, [] as string[]),
        [groupData?.locations_hierarchy]
    );
    const {
        data: SalesDataAllLocations,
        error: SalesDataError,
        refetch: refetchSales,
    } = useQuery(SALES_ALL_LOCATIONS, {
        variables: {
            venueId,
            splitDate: parsedDate.format("YYYY-MM-DD"),
            locations: allSublocationIds ?? [],
        },
        fetchPolicy: "network-only",
    });

    const {
        data: PromoData,
        error: PromoDataError,
        refetch: refetchPromos,
    } = useQuery(PROMO_ACCOUNTS, {
        variables: {
            venueId,
            splitDate: parsedDate.format("YYYY-MM-DD"),
        },
        fetchPolicy: "network-only",
    });

    const {
        data: PosAndGamingVarianceData,
        error: PosAndGamingVarianceDataError,
        refetch: refetchPosAndGamingVariance,
    } = useQuery(CASH_VARIANCE_FOR_ALL_POS_LOCATIONS_AND_GAMING, {
        variables: {
            date: parsedDate.format("YYYY-MM-DD"),
            venueId,
        },
        fetchPolicy: "network-only",
    });

    const {
        data: WageringData,
        error: WageringVarianceDataError,
        refetch: refetchWageringVariance,
    } = useQuery(WAGERING_QUERY, {
        variables: {
            date: parsedDate.format("YYYY-MM-DD"),
            venueId,
        },
        fetchPolicy: "network-only",
    });

    const { refetch: refetchGaming } = useQuery(GAMING_DATA_QUERY, {
        variables: {
            venueId,
            splitDate: parsedDate.format("YYYY-MM-DD"),
        },
        fetchPolicy: "network-only",
    });

    const {
        data: PettyCashData,
        error: PettyCashError,
        refetch: refetchPettyCash,
    } = useQuery(PETTY_CASH_QUERY, {
        variables: {
            splitDate: parsedDate.format("YYYY-MM-DD"),
            venueId,
        },
        fetchPolicy: "network-only",
    });

    // This is invoked on top banner change event
    // This is to act as a psuedo realtime approach to updating the shift report
    const onTopBannerUpdate = () => {
        refetchPettyCash();
        refetchGaming();
        refetchPosAndGamingVariance();
        refetchWageringVariance();
        refetchPromos();
        refetchSales();
    };

    // TODO IMPLEMENT THIS IN THE LOCATIONS SECTION
    useEffect(() => {
        window.addEventListener(TOP_BANNER_EVENT_NAME, onTopBannerUpdate);
        return () =>
            window.removeEventListener(TOP_BANNER_EVENT_NAME, onTopBannerUpdate);
    }, []);

    if (!groupData) {
        throw new Error(
            "No group data in CashupDailyReport, this should be impossible"
        );
    }

    const { shiftReportPrompts } = useGetShiftReportPrompts({ venueId });
    const { cashups, isLoading } = useGetCashups(
        {
            range: {
                start: parsedDate,
                end: parsedDate,
            },
            venueId: venueId,
            disabled: !date,
        },
        groupData
    );

    const {
        isLoading: loadingShiftReportPromptRecords,
        shiftReportPromptRecordsByPromptId,
    } = useListShiftReportPromptRecords({ date, venueId });

    const cashupsByLocationId = useMemo(
        () => chain(cashups).groupBy("locationId").value(),
        [cashups]
    );

    const reportPrompts = useMemo(() => {
        return shiftReportPrompts?.reduce<{
            groupReportPrompts: ShiftReportPrompt[];
            locationReportPrompts: ShiftReportPrompt[];
        }>(
            (result, reportPrompt) => {
                if (reportPrompt.promptLocationId) {
                    result.locationReportPrompts.push(reportPrompt);
                } else {
                    result.groupReportPrompts.push(reportPrompt);
                }
                return result;
            },
            {
                groupReportPrompts: [],
                locationReportPrompts: [],
            }
        );
    }, [shiftReportPrompts]);

    const reportPromptsGroupedByPromptType = useMemo(
        () =>
            chain(reportPrompts?.groupReportPrompts)
                .groupBy("promptCategory")
                .value(),
        [reportPrompts]
    );

    const locationsByLocationType = useMemo(
        () =>
            chain(groupData.locations_hierarchy)
                .uniqBy("location_id")
                .filter(
                    ({ venue, location_type, sub_locations }) =>
                        venue === venueId &&
                        supportedLocationTypes.includes(location_type!) &&
                        sub_locations.length > 0
                )
                .groupBy(({ location_type }) => location_type)
                .value(),
        [groupData.locations_hierarchy, venueId]
    );

    // Assuming that only pos can have different shifts.
    const isSingleShift = useMemo(
        () =>
            groupData.group.settings.single_shift_locations?.includes(
                LocationType.pos
            ) ?? false,
        [groupData.group.settings.single_shift_locations]
    );

    const ParsedAllLocationsSalesData = useMemo(() => {
        if (!SalesDataAllLocations || !WageringData) return;
        let salesTotal = 0;
        const salesTotalSalesData: { [classId: string]: number } = {};

        SalesDataAllLocations.cube.forEach((posEntry: any) => {
            const match = groupData.classes.find(
                (currentClass) => posEntry.splits.classId === currentClass.class_id
            );

            if (match) {
                salesTotal += posEntry.splits.sumTotalIncTax;

                if (salesTotalSalesData[match.class_id]) {
                    salesTotalSalesData[match.class_id] =
                        Math.round(
                            (salesTotalSalesData[match.class_id] +
                                posEntry.splits.sumTotalIncTax) *
                                100
                        ) / 100;
                } else {
                    salesTotalSalesData[match.class_id] =
                        Math.round(posEntry.splits.sumTotalIncTax * 100) / 100;
                }
            } else {
                // TODO ADD SENTRY / BUGSNAG LOG
                console.log("Unknown class");
            }
        });

        WageringData.cube.forEach((varianceEntry: any) => {
            salesTotal += varianceEntry.cashup.wageringDataCommission;
        });

        const ClassesArray: {
            className: string;
            amount: number;
            index: number;
        }[] = [];

        Object.keys(salesTotalSalesData).forEach((classId, index) => {
            const match = groupData.classes.find(
                (currentClass) => classId === currentClass.class_id
            );
            if (match)
                ClassesArray.push({
                    className: match.name,
                    amount: salesTotalSalesData[classId],
                    index,
                });
        });

        return { salesTotal, ClassesArray };
    }, [SalesDataAllLocations, WageringData]);

    const VarianceTotal = useMemo(() => {
        if (!PosAndGamingVarianceData || !WageringData) return 0;
        let currentVarianceTotal = 0;

        PosAndGamingVarianceData.cube.forEach((varianceEntry: any) => {
            currentVarianceTotal += varianceEntry.splits.sumTotalIncTax;
        });
        WageringData.cube.forEach((varianceEntry: any) => {
            currentVarianceTotal += varianceEntry.cashup.cashCountCashVariance;
        });
        return currentVarianceTotal;
    }, [PosAndGamingVarianceData, WageringData]);

    const PromotionsTotal = useMemo(() => {
        if (!PromoData) return 0;
        let currentTotal = 0;

        PromoData.cube.forEach((promotionEntry: any) => {
            currentTotal += promotionEntry.splits.totalIncTax;
        });

        return currentTotal * -1;
    }, [PromoData]);

    const PettyCashTotal = useMemo(() => {
        if (!PettyCashData) return 0;
        let currentTotal = 0;

        PettyCashData.cube.forEach((pettyCashEntry: any) => {
            currentTotal += pettyCashEntry.splits.totalIncTax;
        });

        return currentTotal * -1;
    }, [PettyCashData]);

    useEffect(() => {
        if (reportPrompts && shiftReportPromptRecordsByPromptId) {
            const commentReport = reportPrompts.groupReportPrompts.map(
                ({ promptText, promptId }) => {
                    return {
                        prompt: promptText,
                        answer:
                            shiftReportPromptRecordsByPromptId[promptId]?.body ?? "",
                    };
                }
            );

            updateCashupReportCsv(
                appendCommentsToCSV(
                    [
                        {
                            section: "venue",
                            content:
                                groupData!.venues.find(
                                    ({ venue_id }) => venue_id === venueId
                                )?.name ?? "",
                        },
                    ],
                    commentReport
                )
            );

            Object.entries(locationsByLocationType).forEach(
                ([locationTypeString, locations]) => {
                    const locationType = Number(locationTypeString) as LocationType;
                    updateCashupReportCsv([
                        {
                            content: locations
                                .map(
                                    parseLocationCashupsForDailyReportExport({
                                        cashupsByLocationId,
                                        classes: groupData.classes,
                                        locationType,
                                        singleShift: isSingleShift,
                                    })
                                )
                                .map(({ content }) => content)
                                .join(",,,\n"),
                            section: locationType,
                        },
                    ]);
                }
            );
        }
    }, [
        cashupsByLocationId,
        groupData,
        isSingleShift,
        locationsByLocationType,
        reportPrompts,
        shiftReportPromptRecordsByPromptId,
        shiftReportPrompts,
        updateCashupReportCsv,
        venueId,
    ]);

    const generatePdfWithCanvas = async () => {
        const exportElements = document.querySelectorAll(".print");
        if (!exportElements.length) {
            throw new Error("no export elements");
        }
        const report = new JsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4",
        });
        const pdfWidth = report.internal.pageSize.getWidth();
        const pdfHeight = report.internal.pageSize.getHeight();
        const title = `Shift Report: ${
            groupData!.venues.find(({ venue_id }) => venue_id === venueId)?.name ??
            ""
        } ${parsedDate.format("YYYY-MM-DD").toString()}`;
        report.setFontSize(18);
        report.setFont("PTSans", "bold");

        const titleWidth =
            (report.getStringUnitWidth(title) * report.getFontSize()) /
            report.internal.scaleFactor;
        const xOffset = report.internal.pageSize.getWidth() / 2 - titleWidth / 2;

        report.text(title, xOffset, 15);

        const canvas: Array<HTMLCanvasElement> = [],
            image: Array<string> = [],
            imgProps: Array<ImageProperties> = [];
        for (let i = 0; i < exportElements.length; i++) {
            canvas[i] = await html2canvas(exportElements[i] as HTMLElement);
            image[i] = canvas[i].toDataURL("image/png", 1.0);
            imgProps[i] = report.getImageProperties(image[i]);
        }
        const ratio =
            imgProps[0].width / pdfWidth > imgProps[0].height / pdfHeight
                ? imgProps[0].width / pdfWidth
                : imgProps[0].height / pdfHeight;
        if (imgProps[0].width / pdfWidth > imgProps[0].height / pdfHeight) {
            report.addImage(
                image[0],
                "JPG",
                20,
                25,
                pdfWidth - 40,
                imgProps[0].height / ratio - 50
            );
        } else {
            report.addImage(
                image[0],
                "JPG",
                20,
                20,
                imgProps[0].width / ratio - 40,
                pdfHeight - 40
            );
        }

        const pdfHeightLocation =
            (imgProps[1].height * pdfWidth) / imgProps[1].width;
        const numberOfE2Pages = Math.ceil(pdfHeightLocation / pdfHeight) - 1;
        report.addPage();
        report.addImage(image[1], "JPG", 20, 0, pdfWidth - 40, pdfHeightLocation);
        for (let i = 1; i <= numberOfE2Pages; i++) {
            report.addPage();
            report.addImage(
                image[1],
                "JPG",
                20,
                -pdfHeight * i,
                pdfWidth - 40,
                pdfHeightLocation
            );
        }

        const pdfHeightToUse = (imgProps[2].height * pdfWidth) / imgProps[2].width;
        const numberOfE3Pages = Math.ceil(pdfHeightToUse / pdfHeight) - 1;
        report.addPage();
        report.addImage(image[2], "JPG", 20, 0, pdfWidth - 40, pdfHeightToUse);
        for (let i = 1; i <= numberOfE3Pages; i++) {
            report.addPage();
            report.addImage(
                image[2],
                "PNG",
                20,
                -pdfHeight * i,
                pdfWidth - 40,
                pdfHeightToUse
            );
        }
        report.save(
            `Report-${parsedDate.format("YYYY-MM-DD").toString()}-${
                groupData!.venues.find(({ venue_id }) => venue_id === venueId)
                    ?.name ?? ""
            }.pdf`
        );
    };

    useEffect(() => {
        let timerGenerate: NodeJS.Timeout, timerStop: NodeJS.Timeout;
        if (printMode) {
            timerGenerate = setTimeout(() => generatePdfWithCanvas(), 3000);
            timerStop = setTimeout(() => {
                setPrintMode(false);
            }, 5000);
        }
        return () => {
            clearTimeout(timerGenerate);
            clearTimeout(timerStop);
        };
    }, [printMode]);

    if (
        SalesDataError ||
        PosAndGamingVarianceDataError ||
        WageringVarianceDataError ||
        PromoDataError ||
        PettyCashError
    ) {
        return (
            <Result
                status="info"
                title="No data was found please populate a Cashup to continue"
            />
        );
    }

    if (
        loadingShiftReportPromptRecords ||
        !shiftReportPromptRecordsByPromptId ||
        !reportPrompts ||
        isLoading ||
        !SalesDataAllLocations ||
        !ParsedAllLocationsSalesData ||
        !WageringData
    ) {
        return (
            <CenteredSingleDivNoHeight style={{ padding: 20, height: 200 }}>
                <Spin />
            </CenteredSingleDivNoHeight>
        );
    }

    if (allSublocationIds === undefined)
        throw Error("allSublocationIds is undefined");

    return (
        <div>
            <div className="print">
                {isMobile ? null : (
                    <Col style={{ width: "98%" }}>
                        <Row
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                alignContent: "flex-end",
                            }}
                        >
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                onClick={() => {
                                    setPrintMode(true);
                                }}
                            >
                                Download Report
                            </Button>
                        </Row>
                    </Col>
                )}{" "}
                <StyledMainCardContainer>
                    <StyledSection>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 12,
                                justifyContent: "flex-start",
                                minWidth: 200,
                            }}
                        >
                            <SectionTitle title="Key Numbers" />

                            <Card bordered={false}>
                                <Statistic
                                    title="Total Sales"
                                    value={ParsedAllLocationsSalesData.salesTotal}
                                    precision={2}
                                    prefix={"$"}
                                    formatter={formatter}
                                />
                            </Card>
                            <Card bordered={false}>
                                <Statistic
                                    title="Variance"
                                    value={VarianceTotal}
                                    precision={2}
                                    prefix={"$"}
                                    formatter={formatter}
                                />
                            </Card>

                            <Card bordered={false}>
                                <Statistic
                                    title="Petty Cash"
                                    value={PettyCashTotal}
                                    precision={2}
                                    prefix={"$"}
                                    formatter={formatter}
                                />
                            </Card>

                            <Card bordered={false}>
                                <Statistic
                                    title="Promotions"
                                    value={PromotionsTotal}
                                    precision={2}
                                    prefix={"$"}
                                    formatter={formatter}
                                />
                            </Card>
                        </div>
                    </StyledSection>
                    <SalesBreakdown {...ParsedAllLocationsSalesData} />
                    <GamingReport />
                </StyledMainCardContainer>
            </div>
            <div className="print">
                <Container>
                    <RenderLocationCards
                        locations={locationsByLocationType}
                        venueId={venueId}
                        groupData={groupData}
                        parsedDate={parsedDate}
                        printMode={printMode}
                    />
                </Container>
            </div>
            <div className="print">
                {reportPrompts.groupReportPrompts.length > 0 && (
                    <Container>
                        <StyledCommentsSection>
                            <SectionTitle title="Shift Details" />

                            <CashupDailyReportCommentsNew
                                shiftReportPrompts={reportPrompts.groupReportPrompts}
                                shiftReportPromptRecordsByPromptId={
                                    shiftReportPromptRecordsByPromptId
                                }
                                date={date}
                                venueId={venueId}
                                shiftReportPromptsGroupedById={
                                    reportPromptsGroupedByPromptType
                                }
                            />
                        </StyledCommentsSection>
                    </Container>
                )}
            </div>
        </div>
    );
};

import React, { useState } from "react";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { TransactionsFormTransfersOffice } from "Pages/CashupHome/ExcelTable/TransactionsFormTransfersOffice";
import { AntDFormState, AntDFormStateWithoutSplit } from "@types";

import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    EmptyDiv,
    StyledButtonFullWidth,
    StyledModal,
    StyledModalDiv,
} from "../StyledComponents";

const TransferModal = ({
    tableColumnData,
    locations,
    hierarchicalLocations,
    onSubmit,
    disabled,
    onOpen,
    accountsData,
    classesData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
}: {
    tableColumnData: InitDataResponseType;
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    onSubmit: (data: AntDFormStateWithoutSplit[]) => void;
    disabled?: boolean;
    onOpen?: () => void;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    onPaymentTransactionSubmission: (data: AntDFormState[]) => void;
    onPaymentTransactionRemove: (data: AntDFormState[]) => void;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
        onOpen?.();
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const TransactionsCount = () => {
        const totalTransactions =
            tableColumnData.transfer_transactions?.length +
            tableColumnData.transfer_transactions_to?.length +
            tableColumnData.payment_transactions.length;

        return totalTransactions
            ? `${totalTransactions} ${
                  totalTransactions === 1 ? "Transaction" : "Transactions"
              }`
            : `0 Transactions`;
    };

    return (
        <StyledModalDiv numberofitems={4}>
            <StyledButtonFullWidth onClick={showModal}>
                {TransactionsCount()}
            </StyledButtonFullWidth>

            <StyledModal
                open={isModalOpen}
                onCancel={handleCancel}
                destroyOnClose
                keyboard={false}
                footer={null}
                title={<div style={{ height: 20 }}></div>}
            >
                <div>
                    <TransactionsFormTransfersOffice
                        locations={locations}
                        onTransferTransactionSubmission={onSubmit}
                        TransferTransactions={
                            tableColumnData.transfer_transactions ?? []
                        }
                        TransferTransactionsReadOnly={
                            tableColumnData.transfer_transactions_to ?? []
                        }
                        PaymentTransactionData={
                            tableColumnData.payment_transactions ?? []
                        }
                        hierarchicalLocations={hierarchicalLocations}
                        disabled={disabled}
                        classesData={classesData}
                        accountsData={accountsData}
                        onPaymentTransactionSubmission={
                            onPaymentTransactionSubmission
                        }
                        onPaymentTransactionRemove={onPaymentTransactionRemove}
                        TransactionsForFloat
                        forceIncludeBank
                        onModalClose={handleOk}
                        isPaymentNegative={true}
                    />
                </div>
            </StyledModal>
        </StyledModalDiv>
    );
};
export const TransferModalComponent = ({
    locations,
    hierarchicalLocations,
    tableData,
    onSubmit,
    onOpen,
    accountsData,
    classesData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    disabled,
}: {
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    tableData: InitDataResponseType[];
    onSubmit: (data: AntDFormStateWithoutSplit[], rowIndex: number) => void;
    onOpen?: () => void;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    onPaymentTransactionSubmission: (
        data: AntDFormState[],
        rowIndex: number
    ) => void;
    onPaymentTransactionRemove: (data: AntDFormState[], rowIndex: number) => void;
    disabled: boolean;
}) => {
    return (
        <div style={{ paddingTop: 12 }}>
            <div
                style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    minWidth: "1200px",
                }}
            >
                <EmptyDiv numberofitems={4}>Transfers / Payments</EmptyDiv>
                {tableData.map((tableColumnData, key) => {
                    return (
                        <TransferModal
                            tableColumnData={tableColumnData}
                            key={key}
                            locations={locations}
                            hierarchicalLocations={hierarchicalLocations}
                            onSubmit={(data) => {
                                onSubmit(data, key);
                            }}
                            disabled={
                                tableColumnData.safe_data.signed_off_by_user_id !==
                                    undefined || disabled
                            }
                            onOpen={onOpen}
                            accountsData={accountsData}
                            classesData={classesData}
                            onPaymentTransactionSubmission={(data) =>
                                onPaymentTransactionSubmission(data, key)
                            }
                            onPaymentTransactionRemove={(data) =>
                                onPaymentTransactionRemove(data, key)
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

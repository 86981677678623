import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { LocationItem } from "../../../@generated";
import { AppendContentTypeHeader } from "./UsersAPI";

export type locationBalances = {
    location_id: string;
    external_id: string;
    active: number;
    name: string;
    group_id: string;
    parent_id: string | null;
    balance: number;
    sub_locations: locationBalances[];
};

export type ExtendedLocationItem = {
    location_id: string;
    external_id: string | null;
    active: number;
    name: string;
    group_id: string;
    parent_id: string | null;
} & LocationItem;

export type ExtendedLocationItemWithChildren = {
    location_id: string;
    external_id: string | null;
    active: number;
    name: string;
    group_id: string;
    parent_id: string | null;
    sub_locations: ExtendedLocationItemWithChildren[];
    location_type?: number;
} & LocationItem;

export const locationsAPI = createApi({
    reducerPath: "LocationsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getLocations: builder.query<ExtendedLocationItem[], null>({
            query: () => ({
                url: "/locations",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),

        getLocationsHierarchical: builder.query<
            ExtendedLocationItemWithChildren[],
            null
        >({
            query: () => ({
                url: "/v2/locations",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),

        getLocationBalances: builder.query<locationBalances[], null>({
            query: () => ({
                url: "/locations/balances",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const {
    useGetLocationsQuery,
    useGetLocationBalancesQuery,
    useGetLocationsHierarchicalQuery,
} = locationsAPI;

import React, { useState, useMemo } from "react";
import { Row, Space, Col, Typography, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ReportItem } from "@interfaces";
import CashupReportLineChart from "./CashupReportLineChart";
import {
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    CustomDataTable,
    TableTitle,
    DownloadLoadingContainer,
} from "./CashupReportStyled";
import {
    getAllUniqueKeys,
    payoutColumnGenerator,
    totalsForPayouts,
    getAllDates,
    prepareLineChartDataForPayouts,
    downloadPdfReport,
    downloadCsvReport,
    prepareGamingCsvData,
} from "../utils";

const { Title } = Typography;

interface GamingPayoutReportProps {
    reportData: ReportItem[];
    areaName: string;
    reportType: string;
    date: { start_date: string; end_date: string };
    venueName: string;
}

const CashupPayoutsReport: React.FC<GamingPayoutReportProps> = ({
    reportData,
    areaName,
    reportType,
    date,
    venueName,
}) => {
    const [download, setDownload] = useState(false as boolean);
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //sort data by date
    const sortedData = useMemo(() => {
        const sortedCopy = [...reportData[0].data];
        return sortedCopy
            .map((item, index) => ({
                ...item,
                key: `${item.date}-${item.Location}-${item.Tender}-${index}`,
            }))
            .sort((a, b) => {
                const dateA = dayjs(a.date, "DD/MM/YYYY");
                const dateB = dayjs(b.date, "DD/MM/YYYY");
                return dateA.diff(dateB);
            });
    }, [reportData]);

    //extracting unique keys from report data
    const allKeys: string[] = useMemo(
        () =>
            reportData.length > 0
                ? (getAllUniqueKeys(reportData[0].data) as string[])
                : [],
        [reportData]
    );

    //prepare columns
    const columns = useMemo(
        () => payoutColumnGenerator(allKeys, true, sortedColumn, sortDirection),
        [allKeys, sortedColumn, sortDirection]
    );

    //prepare total columns
    const totalColumns = useMemo(
        () => payoutColumnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (reportData.length > 0 ? totalsForPayouts(reportData) : {}),
        [reportData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...totals,
    };

    //prepare labels for line chart
    const lineChartXLabels: string[] = useMemo(
        () => getAllDates(date.start_date, date.end_date),
        [date]
    );

    //prepare line chart data for Tender Amount
    const lineChartDataForPayouts: {
        name: string;
        data: number[];
    }[] = useMemo(
        () =>
            prepareLineChartDataForPayouts(
                reportData,
                "Tender Amount",
                lineChartXLabels
            ),
        [reportData, lineChartXLabels]
    );

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total: sortedData.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${areaName} ${reportType}`;
        await downloadPdfReport(
            reportName,
            "gaming-payouts-report",
            venueName,
            date
        );
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = prepareGamingCsvData(reportData, totals);
        const reportName = `${areaName} ${reportType}`;
        downloadCsvReport(reportName, venueName, date, csvData);
        setDownload(false);
    };

    if (reportData[0].data.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    return (
        <>
            <div id="gaming-payouts-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${areaName} - ${reportType}`}</Title>
                        </Col>
                        <Col>
                            <div style={{ display: "flex", gap: "15px" }}>
                                <Tooltip title="Download PDF">
                                    <FilePdfOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                {/* <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onCSVClick}
                                    />
                                </Tooltip> */}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col md={24} lg={24} xs={24}>
                            <CashupReportLineChart
                                data={lineChartDataForPayouts}
                                labels={lineChartXLabels}
                                chartType={"Payouts"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[20, 2]}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`Total (${dayjs(
                                    date.start_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")} - ${dayjs(
                                    date.end_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")})`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        rowKey="date"
                                        dataSource={[totalRow]}
                                        columns={totalColumns}
                                        size="small"
                                        scroll={{ x: true }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                    {reportData.map((item: ReportItem) => (
                        <Row gutter={[20, 2]} key={`Payouts`}>
                            <Col xs={24}>
                                <LocationContainer>
                                    <TableTitle>{`Payouts`}</TableTitle>
                                    <TotalTableContainer>
                                        <CustomDataTable
                                            rowKey={(record: Record<string, any>) =>
                                                record.key
                                            }
                                            dataSource={sortedData}
                                            columns={columns}
                                            size="small"
                                            scroll={{ x: true }}
                                            tableLayout="fixed"
                                            pagination={
                                                download ? false : paginationConfig
                                            }
                                            onChange={handleTableChange}
                                        />
                                    </TotalTableContainer>
                                    <TotalTableContainer>
                                        <CustomTotalDataTable
                                            rowKey="date"
                                            dataSource={[totalRow]}
                                            columns={totalColumns}
                                            size="small"
                                            scroll={{ x: true }}
                                            tableLayout="fixed"
                                            pagination={false}
                                        />
                                    </TotalTableContainer>
                                </LocationContainer>
                            </Col>
                        </Row>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupPayoutsReport;

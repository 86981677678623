export enum CashupStatus {
    draft = 1,
    unapproved = 2,
    approved = 3,
}

export enum CashupReportType {
    PosClasses = "pos-classes",
    PosTenders = "pos-tenders",
    PosVariances = "pos-variances",
    PosClassesSummary = "pos-classes-summary",
    PosTendersSummary = "pos-tenders-summary",
    PosVariancesSummary = "pos-variances-summary",
    GamingMeteredNetProfit = "gaming-meteredNetProfit",
    GamingActualNetProfit = "gaming-actualNetProfit",
    GamingPayouts = "gaming-payouts",
    WageringPayouts = "wagering-payouts",
    TransactionsPettyCash = "transactions-pettyCash",
    GamingVariance = "gaming-gamingVariance",
    AdminShiftReport = "admin-shiftReport",
    AdminShiftMetrics = "admin-shiftReport-prompts",
    ApprovalsReport = "admin-approvalsReport",
    BankableReport = "admin-bankable",
    BankableOverview = "admin-bankableOverview",
}

export enum BankableOverviewType {
    "open_balance" = "Open Balance",
    "cash_increase" = "Cash Increase",
    bankable = "Bankable",
}

import { Col, DatePicker, Input, InputNumber, Spin, Switch } from "antd";
import React, { CSSProperties, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

interface Props {
    columns: Array<{
        content: string;
        type?: any;
        editable?: boolean;
        loading?: boolean;
        spanStyle?: CSSProperties;
    }>;
    onEditableColumnContentChanged?: (updatedContent: string) => void;
    isTitle?: boolean;
    border?: "TOP" | "BOTTOM";
    promptType: number;
}

const borderHelper = (
    currentIndex: number,
    length: number,
    border?: "TOP" | "BOTTOM"
): CSSProperties => {
    const borderRadius = 4;
    const objectStyles: CSSProperties = {};

    if (border) {
        if (currentIndex === 0) {
            if (border === "TOP") {
                objectStyles["borderTopLeftRadius"] = borderRadius;
            } else {
                objectStyles["borderBottomLeftRadius"] = borderRadius;
            }
        }

        if (currentIndex === length - 1) {
            if (border === "TOP") {
                objectStyles["borderTopRightRadius"] = borderRadius;
            } else {
                objectStyles["borderBottomRightRadius"] = borderRadius;
            }
        }
    }

    return objectStyles;
};

const StyledReportItemEntry = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding-bottom: 12px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

interface KeyDown {
    key: string;
    currentTarget: HTMLInputElement;
}

interface OnBlur {
    target: HTMLInputElement;
}

const FormHelper = ({
    content,
    onEditableColumnContentChanged,
    setEditingColIndex,
    fieldType,
}: {
    content: string;
    onEditableColumnContentChanged?: (value: string) => void;
    setEditingColIndex: (value?: number) => void;
    fieldType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
}) => {
    switch (fieldType) {
        // Text
        case 1:
            return (
                <StyledInput
                    // autoFocus
                    defaultValue={content}
                    onBlur={({ target }: OnBlur) => {
                        if (target.value) {
                            onEditableColumnContentChanged?.(target.value);
                        }
                        setEditingColIndex(undefined);
                    }}
                    onKeyDown={({ key, currentTarget }: KeyDown) => {
                        if (key === "Enter") {
                            onEditableColumnContentChanged?.(currentTarget.value);
                            setEditingColIndex(undefined);
                        }
                    }}
                />
            );
            break;
        // Boolean
        case 2:
            return (
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={content === "1"}
                    onChange={(value: boolean) => {
                        onEditableColumnContentChanged?.(value ? "1" : "0");
                        setEditingColIndex(undefined);
                    }}
                    style={{ minWidth: 120 }}
                />
            );
            break;

        // Number
        case 3:
            return (
                <StyledInput
                    // autoFocus
                    defaultValue={content}
                    onBlur={({ target }: OnBlur) => {
                        if (target.value) {
                            onEditableColumnContentChanged?.(target.value);
                        }

                        setEditingColIndex(undefined);
                    }}
                    onKeyDown={({ key, currentTarget }: KeyDown) => {
                        if (key === "Enter") {
                            onEditableColumnContentChanged?.(currentTarget.value);
                            setEditingColIndex(undefined);
                        }
                    }}
                    type="number"
                    style={{ width: "100%" }}
                />
            );
            break;

        // Currency
        case 4:
            return (
                <InputNumber
                    addonBefore="$"
                    defaultValue={content}
                    onChange={(value) => {
                        if (value) {
                            onEditableColumnContentChanged?.(value.toString());
                            setEditingColIndex(undefined);
                        } else {
                            onEditableColumnContentChanged?.("");
                            setEditingColIndex(undefined);
                        }
                    }}
                    style={{ width: "100%" }}
                />
            );
            break;

        // Percentage
        case 5:
            return (
                <InputNumber
                    addonAfter="%"
                    defaultValue={content}
                    onChange={(value) => {
                        if (value) {
                            onEditableColumnContentChanged?.(value.toString());
                            setEditingColIndex(undefined);
                        } else {
                            onEditableColumnContentChanged?.("");
                            setEditingColIndex(undefined);
                        }
                    }}
                    style={{ width: "100%" }}
                />
            );
            break;

        // Datetime
        case 6:
            return (
                <DatePicker
                    showTime
                    onChange={(_, dateString: string | string[]) => {
                        onEditableColumnContentChanged?.(dateString as string);
                        setEditingColIndex(undefined);
                    }}
                    defaultValue={content ? dayjs(content) : undefined}
                    style={{ width: "100%" }}
                />
            );

        // Textarea
        case 7:
            return (
                <StyledInput.TextArea
                    // autoFocus
                    defaultValue={content}
                    onBlur={({ target }: { target: HTMLTextAreaElement }) => {
                        if (target.value) {
                            onEditableColumnContentChanged?.(target.value);
                        }
                        setEditingColIndex(undefined);
                    }}
                    onKeyDown={({ key, currentTarget }) => {
                        if (key === "Enter") {
                            onEditableColumnContentChanged?.(currentTarget.value);
                            setEditingColIndex(undefined);
                        }
                    }}
                    style={{ width: "100%" }}
                />
            );
            break;

        default:
            throw new Error(`Option is not implemented ${fieldType}`);
    }
};

export const CashupDailyReportItemNew: React.FC<Props> = ({
    columns,
    onEditableColumnContentChanged,
    isTitle,
    border,
    promptType,
}) => {
    const colSpan = 23 / columns.length;
    const [editingColIndex, setEditingColIndex] = useState<number>();

    return (
        <StyledReportItemEntry>
            {columns.map(({ content, editable, loading }, i) => {
                const borderStyling = borderHelper(i, columns.length, border);

                if (i === 0)
                    return (
                        <StyledFirstCol
                            onDoubleClick={() => {
                                if (editable) {
                                    setEditingColIndex(i);
                                }
                            }}
                            $editable={Boolean(editable)}
                            $center={Boolean(loading)}
                            key={i}
                            $title={isTitle ?? false}
                            span={colSpan}
                            style={borderStyling}
                        >
                            {content}
                        </StyledFirstCol>
                    );

                return (
                    <StyledCol
                        onDoubleClick={() => {
                            if (editable) {
                                setEditingColIndex(i);
                            }
                        }}
                        onClick={() => {
                            if (editable) {
                                setEditingColIndex(i);
                            }
                        }}
                        $editable={Boolean(editable)}
                        $center={Boolean(loading)}
                        key={i}
                        $title={isTitle ?? false}
                        span={colSpan}
                        style={borderStyling}
                    >
                        {editingColIndex === i && onEditableColumnContentChanged ? (
                            <FormHelper
                                content={content}
                                onEditableColumnContentChanged={
                                    onEditableColumnContentChanged
                                }
                                setEditingColIndex={setEditingColIndex}
                                fieldType={promptType as 1 | 2 | 3 | 4 | 5 | 6 | 7}
                            />
                        ) : loading ? (
                            <Spin />
                        ) : (
                            <FormHelper
                                content={content}
                                onEditableColumnContentChanged={
                                    onEditableColumnContentChanged
                                }
                                setEditingColIndex={setEditingColIndex}
                                fieldType={promptType as 1 | 2 | 3 | 4 | 5 | 6 | 7}
                            />
                        )}
                    </StyledCol>
                );
            })}
        </StyledReportItemEntry>
    );
};

const StyledInput = styled(Input)`
    height: 100%;
`;

// TODO Smart styling so that the border's don't overlap!!!!

const StyledCol = styled(Col)<{
    $editable: boolean;
    $center: boolean;
    $title: boolean;
}>`
    background: ${({ $title }) => ($title ? "rgb(249, 250, 251);" : "#FFF;")}
    min-height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 12px;

    ${({ $editable }) => ($editable ? `cursor: pointer` : "")};

    ${({ $center }) => ($center ? `justify-content: center` : "")};
`;

const StyledFirstCol = styled(Col)<{
    $editable: boolean;
    $center: boolean;
    $title: boolean;
}>`
    background: ${({ $title }) => ($title ? "rgb(249, 250, 251);" : "#FFF;")}
    height: 40px;
    display: flex;
    align-items: center;
    width: 60%;
    padding: 4px 12px;

    ${({ $editable }) => ($editable ? `cursor: pointer` : "")};

    ${({ $center }) => ($center ? `justify-content: center` : "")};

    @media (max-width: 768px) {
        width: 100%;
    }
`;

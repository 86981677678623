import { AntDFormStateWithoutSplit } from "@types";
import { Button, Collapse, Result, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import styled from "styled-components";
import { TransferTransactionInit } from "../CashupTabs/POS/@types";
import { extractSubLocation } from "../../../utils/utilities";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import {
    checkForSublocations,
    TransferModalFormV2,
} from "./TransactionForms/TransferModalFormV2";
import { TransfersTransactionParser } from "./TransactionsFormGaming";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ExtendedTreeData } from "./TransactionsFormTransfersOffice";
import { ModalFooter } from "Components/Form/ModalFooter";
import { DraggableModal } from "Components/DraggableModal";

const { Panel } = Collapse;

interface Props {
    onModalClose: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    TransferTransactionsReadOnly: TransferTransactionInit[];
    defaultActiveKey?: string | number | (string | number)[] | undefined;
    disabled?: boolean;
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

const TransactionsFormTransferOnlyComponent: React.FC<Props> = ({
    onModalClose,
    onTransferTransactionSubmission,
    TransferTransactions,
    hierarchicalLocations,
    currentLocationID,
    TransferTransactionsReadOnly,
    defaultActiveKey,
    disabled,
}) => {
    const [isFormNeedToSave, setIsFormNeedToSave] = useState<
        Array<{ [name: string]: boolean }>
    >([]);
    const [isVisible, setIsVisible] = useState(false);
    const setFormNeedToSave = useCallback(
        (category: string, isToAdd: boolean) => {
            if (isToAdd) {
                if (
                    !isFormNeedToSave.some((form) =>
                        Object.keys(form).includes(category)
                    )
                ) {
                    setIsFormNeedToSave([...isFormNeedToSave, { [category]: true }]);
                }
            } else {
                if (
                    isFormNeedToSave.some((form) =>
                        Object.keys(form).includes(category)
                    )
                ) {
                    const index = isFormNeedToSave.findIndex(
                        (form) => Object.keys(form)[0] === category
                    );
                    const isFormNeedToSaveCopy = [...isFormNeedToSave];
                    isFormNeedToSaveCopy.splice(index, 1);
                    setIsFormNeedToSave(isFormNeedToSaveCopy);
                }
            }
        },
        [isFormNeedToSave, setIsFormNeedToSave]
    );
    const formNamesNeedToSave = useMemo(() => {
        const forms = isFormNeedToSave.filter((item) => Object.values(item)[0]);

        let result = "";
        forms.forEach((form) => {
            result = result.concat(`${Object.keys(form)[0]} `);
        });
        return result;
    }, [isFormNeedToSave]);
    const closeModal = useCallback(() => {
        if (
            isFormNeedToSave.some(
                (formNeedToSave) => Object.values(formNeedToSave)[0]
            )
        ) {
            setIsVisible(true);
        } else {
            onModalClose && onModalClose();
        }
    }, [isFormNeedToSave, onModalClose]);

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialTransfersTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactions.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactions,
                          currentLocationID
                      )
                    : [
                          {
                              formData: [{ name: "From", value: currentLocationID }],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    const processTransformLocations = useCallback(
        (isReadonly: boolean) => {
            const result: ExtendedTreeData[] = [],
                floatLocations: ExtendedLocationItemWithChildren[] = [];
            hierarchicalLocations.forEach((location, index) => {
                result.push({
                    key: index,
                    title: location.name,
                    value: location.location_id,
                    children: location.sub_locations
                        ? isReadonly
                            ? extractSubLocation(
                                  location.sub_locations,
                                  location.name
                              ).sort((subLocationA, subLocationB) =>
                                  subLocationA.title.localeCompare(
                                      subLocationB.title
                                  )
                              )
                            : extractSubLocation(location.sub_locations).sort(
                                  (subLocationA, subLocationB) =>
                                      subLocationA.title.localeCompare(
                                          subLocationB.title
                                      )
                              )
                        : [],
                    disabled: checkForSublocations(location),
                    venue: location.venue,
                });
            });
            if (floatLocations.length !== 0) {
                const floatLocationsChildren = extractSubLocation(
                    floatLocations
                ).sort((subLocationA, subLocationB) =>
                    subLocationA.title.localeCompare(subLocationB.title)
                );

                result.forEach((location) => {
                    if (location.title.includes("Safe")) {
                        const itemsWithMatchingVenueId =
                            floatLocationsChildren.filter(
                                (item) => item.venue === location.venue
                            );
                        location.children = location.children?.concat(
                            itemsWithMatchingVenueId
                        );
                        location.disabled = true;
                    }
                });
            }
            return result;
        },
        [hierarchicalLocations]
    );
    const transformLocations = useMemo(
        () => processTransformLocations(true),
        [processTransformLocations]
    );
    const transformLocationsReadonly = useMemo(
        () => processTransformLocations(true),
        [processTransformLocations]
    );
    const transferReadOnlyFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },

                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ],
        [currentLocationID, transformLocationsReadonly]
    );
    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactionsReadOnly.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactionsReadOnly,
                          currentLocationID,
                          true
                      )
                    : [],
            []
        );

    const shouldShowTransfersTo = useMemo(() => {
        return initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length > 0;
    }, [initialTransfersReadOnlyTransactionsDataWithAppendedFormID]);

    return (
        <StyledFormContainer>
            <Collapse
                accordion
                expandIconPosition="end"
                defaultActiveKey={defaultActiveKey}
            >
                {/*<Panel*/}
                {/*    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}*/}
                {/*    key="1"*/}
                {/*>*/}
                {/*    <TransferModalFormV2*/}
                {/*        currentLocationID={currentLocationID}*/}
                {/*        fields={transferFields}*/}
                {/*        initialDataForForms={*/}
                {/*            initialTransfersTransactionsDataWithAppendedFormID*/}
                {/*        }*/}
                {/*        onChange={onTransferTransactionSubmission}*/}
                {/*        disabled={disabled}*/}
                {/*        setFormNeedToSave={setFormNeedToSave}*/}
                {/*    />*/}
                {/*</Panel>*/}
                {
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferReadOnlyFields}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                            disabled={disabled}
                        />
                    </Panel>
                }
            </Collapse>
            <ModalFooter
                onClose={closeModal}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
            <DraggableModal
                open={isVisible}
                onCancel={() => setIsVisible(false)}
                onOk={() => setIsVisible(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <Result
                    status="warning"
                    title={`${formNamesNeedToSave} forms not save, please click on save icon before you leave`}
                    extra={
                        <Row style={{ justifyContent: "center" }}>
                            <Button onClick={() => setIsVisible(false)}>OK</Button>
                        </Row>
                    }
                />
            </DraggableModal>
        </StyledFormContainer>
    );
};

export const TransactionsFormTransferOnly = React.memo(
    TransactionsFormTransferOnlyComponent
);

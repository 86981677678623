import React, { useState, useMemo } from "react";
import { Row, Space, Col, Typography, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CashupReportBarChart from "./CashupReportBarChart";
import CashupReportRadialChart from "./CashupReportRadialChart";
import CashupReportTable from "./CashUpReportTable";
import { ReportItem } from "@interfaces";
import {
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    TableTitle,
    DownloadLoadingContainer,
} from "./CashupReportStyled";
import {
    getAllUniqueKeys,
    columnGenerator,
    totalsForAllLocations,
    prepareBarGraphData,
    calculateTotalGamingReturnToHouse,
    downloadPdfReport,
    downloadCsvReport,
    prepareGamingCsvData,
} from "../utils";

const { Title } = Typography;

interface GamingReportProps {
    reportData: ReportItem[];
    areaName: string;
    reportType: string;
    date: { start_date: string; end_date: string };
    venueName: string;
}

const CashupGamingMeteredNetProfitReport: React.FC<GamingReportProps> = ({
    reportData,
    areaName,
    reportType,
    date,
    venueName,
}) => {
    const [download, setDownload] = useState(false as boolean);
    //extracting unique keys from report data
    const allKeys: string[] = useMemo(
        () =>
            reportData.length > 0
                ? (getAllUniqueKeys(reportData[0].data) as string[])
                : [],
        [reportData]
    );

    //prepare columns
    const columns = useMemo(
        () => columnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (reportData.length > 0 ? totalsForAllLocations(reportData) : {}),
        [reportData]
    );

    //update return to house for totals
    const updatedTotals: Record<string, number> = useMemo(
        () => calculateTotalGamingReturnToHouse(totals),
        [totals]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...updatedTotals,
    };

    //prepare radial chart data for return to house
    const radialChartLabels: string[] = useMemo(
        () => allKeys.filter((label) => label === "Return to House"),
        [allKeys]
    );
    const filterRadialChartData: Record<string, number> = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(updatedTotals).filter(([key]) =>
                    radialChartLabels.includes(key)
                )
            ),
        [updatedTotals, radialChartLabels]
    );
    const radialChartData: number[] = useMemo(
        () => Object.values(filterRadialChartData),
        [filterRadialChartData]
    );

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => prepareBarGraphData(reportData), [reportData]);

    //filter data - return to house excluded
    const filteredGraphData = barChartData.graphData.filter(
        (item: { name: string; data: number[] }) => item.name !== "Return to House"
    );
    const modifiedBarChartData = {
        graphData: filteredGraphData,
        labels: barChartData.labels,
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${areaName} ${reportType}`;
        await downloadPdfReport(
            reportName,
            "gaming-metered-report",
            venueName,
            date
        );
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = prepareGamingCsvData(reportData, updatedTotals);
        const reportName = `${areaName} ${reportType}`;
        downloadCsvReport(reportName, venueName, date, csvData);
        setDownload(false);
    };

    if (reportData[0].data.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    return (
        <>
            <div id="gaming-metered-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${areaName} - ${reportType}`}</Title>
                        </Col>
                        <Col>
                            <div style={{ display: "flex", gap: "15px" }}>
                                <Tooltip title="Download PDF">
                                    <FilePdfOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                {/* <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onCSVClick}
                                    />
                                </Tooltip> */}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportBarChart data={modifiedBarChartData} />
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportRadialChart
                                data={radialChartData}
                                labels={radialChartLabels}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[20, 2]}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`Total (${dayjs(
                                    date.start_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")} - ${dayjs(
                                    date.end_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")})`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        rowKey="date"
                                        dataSource={[totalRow]}
                                        columns={columns}
                                        size="small"
                                        scroll={{ x: true }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                    {reportData.map((item: ReportItem) => (
                        <Row gutter={[20, 2]} key={`${item.location_type}`}>
                            <Col xs={24}>
                                <LocationContainer>
                                    <TableTitle>{`${item.location_type}`}</TableTitle>
                                    <CashupReportTable
                                        data={item.data}
                                        sortBoolean={false}
                                        gamingBoolean={true}
                                        downloading={download}
                                    />
                                </LocationContainer>
                            </Col>
                        </Row>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupGamingMeteredNetProfitReport;

import * as React from "react";
import { useEffect, useState } from "react";

import { fetchUserToken } from "../../utils/utilities";
import { GamingSection } from "../../Pages/CashupReport/Components/CashupDailyReport/GamingSection";
import { CentredSpinner } from "../Misc/Loading/CentredSpinner";
import { TableGraphDataItem } from "../../@types";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";

export const GamingReport = (): JSX.Element => {
    const [graphData, setGraphData] = useState<TableGraphDataItem[]>([]);
    const [tableData, setTableData] = useState<TableGraphDataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { venueId, formattedDate } = useVenueSelection();

    useEffect(() => {
        async function fetchGamingData() {
            try {
                const ID_TOKEN = await fetchUserToken();

                if (ID_TOKEN === undefined) {
                    throw Error("Auth token not available");
                }

                fetch(
                    `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/analytics/gaming-report?cashup_date=${formattedDate}&venue_id=${venueId}&content_type=csv`,
                    { headers: { Authorization: `Bearer ${ID_TOKEN}` } }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        setGraphData([
                            {
                                title: "Metered Net Profit",
                                amount: res["Metered NET Profit"],
                                index: 0,
                            },
                            {
                                title: "Turnover",
                                amount: res["Turnover (GST Inc)"],
                                index: 1,
                            },
                        ]);
                        setTableData(
                            Object.entries(res as TableGraphDataItem).map(
                                ([title, amount], index) => ({
                                    title,
                                    amount,
                                    index,
                                })
                            )
                        );
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        setLoading(false);
                    });
            } catch (error) {
                console.error("Error fetching user token:", error);
                setLoading(false);
            }
        }

        setLoading(true);
        fetchGamingData();
    }, []);

    return (
        <>
            {loading ? (
                <CentredSpinner />
            ) : (
                <GamingSection graphData={graphData} tableData={tableData} />
            )}
        </>
    );
};

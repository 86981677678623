import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import React, { useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { OfficePanel } from "./OfficePanel";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { getClassesQuery } from "../../ApiV2/Helpers/getClassesQuery";
import dayjs from "dayjs";

const OfficeTab = () => {
    const { venueId, formattedDate, shiftStatus } = useVenueSelection();
    const {
        data: officeLocations,
        isLoading: officeLocationsIsLoading,
        isFetching,
    } = useQuery(["collapsableOfficeLocations", venueId, formattedDate], () =>
        getLocationsQuery(
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            venueId,
            formattedDate
        )
    );
    const { data: accounts, isLoading: isAccountsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );
    const { data: classes, isLoading: isClassesLoading } = useQuery(
        ["classes", venueId],
        () => getClassesQuery(venueId)
    );
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (
        officeLocationsIsLoading ||
        isAccountsLoading ||
        isClassesLoading ||
        isFetching
    ) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }
    console.log(formattedDate);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {officeLocations
                .filter(
                    (oL: ExtendedLocationItemWithChildren) => oL.location_type === 7
                )
                .map(
                    (
                        currentLocations: ExtendedLocationItemWithChildren,
                        index: number
                    ) => {
                        const panelId = `${formattedDate}-${index}`;

                        return (
                            <CollapsibleTable
                                name={currentLocations.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <OfficePanel
                                    key={currentLocations.name}
                                    name={currentLocations.name}
                                    //@ts-ignore
                                    cashupIds={currentLocations.cashup_ids}
                                    //@ts-ignore
                                    subLocations={currentLocations.sub_locations}
                                    locations={officeLocations.filter(
                                        (a: ExtendedLocationItemWithChildren) =>
                                            a.location_type === 4 ||
                                            a.location_type === 12 ||
                                            a.location_type === 13 ||
                                            a.location_type === 5 ||
                                            a.location_type === 6 ||
                                            a.location_type === 7 ||
                                            a.location_type === 14 ||
                                            a.location_type === 15
                                    )}
                                    hierarchicalLocations={
                                        dayjs(formattedDate).isAfter("2024-08-30")
                                            ? currentLocations.sub_locations.some(
                                                  (a) => a.location_type === 13
                                              )
                                                ? officeLocations.filter(
                                                      // @ts-ignore
                                                      (a) =>
                                                          a.location_type === 7 ||
                                                          a.location_type === 5
                                                  )
                                                : officeLocations.filter(
                                                      (
                                                          a: ExtendedLocationItemWithChildren
                                                      ) =>
                                                          a.location_type === 4 ||
                                                          a.location_type === 12 ||
                                                          a.location_type === 13 ||
                                                          a.location_type === 5 ||
                                                          a.location_type === 6 ||
                                                          a.location_type === 7 ||
                                                          a.location_type === 14 ||
                                                          a.location_type === 15
                                                  )
                                            : officeLocations
                                    }
                                    allLocations={officeLocations}
                                    accountsData={accounts}
                                    classesData={classes}
                                    onClickHandler={() =>
                                        setCurrenTableInFocus?.(panelId)
                                    }
                                    isInFocus={panelId === currentTableInFocus}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default OfficeTab;

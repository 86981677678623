import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import React, { useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { CCUPanel } from "./CCUPanel";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";

const CCUTab = () => {
    const { venueId, formattedDate, shiftStatus, setActivePanel, activePanel } =
        useVenueSelection();
    const {
        data: ccuLocations,
        isLoading: ccuLocationIsLoading,
        isFetching,
    } = useQuery(["collapsableCCULocations", venueId, formattedDate], () =>
        getLocationsQuery([16, 7], venueId, formattedDate)
    );
    const { data: accounts, isLoading: accountsIsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );

    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (ccuLocationIsLoading || accountsIsLoading || isFetching) {
        return <></>;
    }
    //Need to updated to use locations api

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {ccuLocations
                .filter(
                    (ccuLocation: ExtendedLocationItemWithChildren) =>
                        ccuLocation.location_type === 16
                )
                .map(
                    (
                        currentLocation: ExtendedLocationItemWithChildren,
                        index: number
                    ) => {
                        console.log(ccuLocations);
                        const panelId = `ccu-${formattedDate}-${index}`;

                        return (
                            <CollapsibleTable
                                name={currentLocation.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <CCUPanel
                                    key={currentLocation.name}
                                    name={currentLocation.name}
                                    //@ts-ignore
                                    cashupIds={currentLocation.cashup_ids}
                                    //@ts-ignore
                                    subLocations={currentLocation.sub_locations}
                                    locations={ccuLocations}
                                    hierarchicalLocations={[...ccuLocations]}
                                    accountsData={accounts}
                                    onClickHandler={() => {
                                        setActivePanel(`${panelId}`);
                                    }}
                                    isInFocus={panelId === activePanel}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default CCUTab;

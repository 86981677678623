import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { CRTPanel } from "./CRTPanel";

const CRTTab = () => {
    const { venueId, formattedDate, shiftStatus, activePanel, setActivePanel } =
        useVenueSelection();
    const {
        data: crtLocations,
        isLoading: crtLocationsIsLoading,
        isFetching,
    } = useQuery(["collapsableCRTLocations", venueId, formattedDate], () =>
        getLocationsQuery([10, 7], venueId, formattedDate)
    );
    useEffect(() => {}, [shiftStatus]);
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (crtLocationsIsLoading || isFetching) {
        return <></>;
    }

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {crtLocations
                .filter(
                    (crt: ExtendedLocationItemWithChildren) =>
                        crt.location_type === 10
                )
                .map(
                    (
                        currentLocation: ExtendedLocationItemWithChildren,
                        index: number
                    ) => {
                        const panelId = `crt-${formattedDate}-${index}`;

                        return (
                            <CollapsibleTable
                                name={currentLocation.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <CRTPanel
                                    key={currentLocation.name}
                                    name={currentLocation.name}
                                    //@ts-ignore
                                    cashupIds={currentLocation.cashup_ids}
                                    //@ts-ignore
                                    subLocations={currentLocation.sub_locations}
                                    locations={crtLocations}
                                    hierarchicalLocations={[
                                        currentLocation,
                                        ...crtLocations.filter(
                                            (
                                                crt: ExtendedLocationItemWithChildren
                                            ) => crt.location_type === 7
                                        ),
                                    ]}
                                    onClickHandler={(
                                        tableIndex: number | undefined
                                    ) => {
                                        setActivePanel(`${panelId}-${tableIndex}`);
                                    }}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                    panelId={panelId}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default CRTTab;

import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { customPalette } from "../const";

interface PieChartProps {
    data: number[];
    labels: string[];
}

const GraphContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

const CashupReportPieChart: React.FC<PieChartProps> = ({ data, labels }) => {
    const chartOptions = {
        labels: labels,
        legend: {
            show: true,
            position: "bottom" as const,
        },
        colors: customPalette,
        tooltip: {
            enabled: true,
        },
        theme: {
            palette: "palette6",
        },
        responsive: [
            {
                //width for phones
                breakpoint: 480,
                options: {
                    chart: {
                        width: 320,
                    },
                },
            },
            {
                //width for small iPads
                breakpoint: 768,
                options: {
                    chart: {
                        width: 345,
                    },
                },
            },
            {
                //width for other iPads
                breakpoint: 834, //iPad Mini
                options: {
                    chart: {
                        width: 400,
                    },
                },
            },
            {
                //width for bigger iPads
                breakpoint: 1024,
                options: {
                    chart: {
                        width: 420,
                    },
                },
            },
        ],
    };

    const chartSeries = data;

    return (
        <GraphContainer>
            <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="pie"
                width="450"
            />
        </GraphContainer>
    );
};

export default CashupReportPieChart;

import { Space } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { CashupTabHOC } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { FloatsPanel } from "./Floats";
import { DenominationsPanel } from "./SafePanel";
import { TotalBudgetsVarianceTable } from "./Total_Budget_Variance";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import produce from "immer";
import {
    sortCashupIDS,
    sortTableDataByShiftType,
    transformSafeLocationsToSafeLocationDetailByCashup,
} from "utils/utilities";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import { cloneDeep } from "lodash";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { AntDFormState, AntDFormStateWithoutSplit } from "@types";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { TableDataType } from "../POS/POSPanel";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { CommentSection } from "./CommentSection";
import { ApproveButtonSection, ApprovedBySection } from "./ApprovedBySection";
import { TransferModalComponent } from "./TransferModalComponent";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { getClassesQuery } from "../../ApiV2/Helpers/getClassesQuery";
import { getUsers } from "../../ApiV2/Helpers/getUsers";

const ExampleSections = ["Denominations", "Floats", "Total, Budget & Variance"];

const SafeTabComponent: React.FC<{
    safeLocations: ExtendedLocationItemWithChildren[];
}> = ({ safeLocations }) => {
    const { venueId, formattedDate, shiftStatus } = useVenueSelection();

    const { data: users, isLoading: usersLoading } = useQuery(["users"], () =>
        getUsers()
    );
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    const [updateCashup] = usePushCashupMutation();
    const { data: accounts } = useQuery(["accounts", venueId], () =>
        getAccountsQuery(venueId)
    );
    const { data: classes } = useQuery(["classes", venueId], () =>
        getClassesQuery(venueId)
    );
    const safeLocationDetailByCashupId = useMemo(
        () =>
            safeLocations?.filter(
                (sL: ExtendedLocationItemWithChildren) => sL.location_type === 6
            )[0].sub_locations
                ? transformSafeLocationsToSafeLocationDetailByCashup(
                      // @ts-ignore
                      safeLocations?.filter(
                          (sL: ExtendedLocationItemWithChildren) =>
                              sL.location_type === 6
                      )[0].sub_locations
                  )
                : {},
        [safeLocations]
    );

    const floatLocations = useMemo(
        () =>
            safeLocations?.filter(
                (sL: ExtendedLocationItemWithChildren) => sL.location_type === 14
            ),
        [safeLocations]
    );

    const safeLocationIds = useMemo(() => {
        return (
            safeLocations
                ?.filter(
                    (sL: ExtendedLocationItemWithChildren) => sL.location_type === 6
                )
                //@ts-ignore
                .map(
                    (location: ExtendedLocationItemWithChildren) =>
                        //@ts-ignore
                        location.cashup_ids
                )[0]
        );
    }, [safeLocations]);

    const [safeTableData, setSafeTableData] = React.useState<InitDataResponseType[]>(
        []
    );

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );

    const onRowDataChange = (rowIndex: number, tableData: TableDataType[]) => {
        handleCashupChanged(tableData[rowIndex]);
    };

    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                safeTableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onPaymentSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormState[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accounts,
                safeTableData,
                onRowDataChange
            ),
        300
    );

    const onSignOffCashupCount = (index: number) => {
        const cashup = safeTableData[index];
        const updatedCashup = produce(cashup, (draft) => {
            draft.safe_data.sign_off = true;
        });
        handleCashupChanged(updatedCashup);
    };

    const updateSafeTableData = (cashup: InitDataResponseType) =>
        setSafeTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);

                    draft.sort((cashupA, cashupB) =>
                        sortTableDataByShiftType(
                            cashupA,
                            cashupB,
                            safeLocationDetailByCashupId
                        )
                    );
                }
            });

            return updatedTableData;
        });

    const sortedSafeCashupIDS = useMemo(
        () =>
            safeLocationIds
                ? //@ts-ignore
                  cloneDeep(safeLocationIds).sort((cashupA_ID, cashupB_ID) =>
                      sortCashupIDS(
                          cashupA_ID,
                          cashupB_ID,
                          safeLocationDetailByCashupId
                      )
                  )
                : [],
        [safeLocationIds, safeLocationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedSafeCashupIDS,
        onCashupInitialized: updateSafeTableData,
        onCashupUpdated: updateSafeTableData,
    });

    const syncTableDataStateDebounced = useDebouncedCallback(
        onRowDataChange,
        DEFAULT_DEBOUNCE_TIME
    );

    const onCommentChange = (
        rowIndex: number,
        comment: string,
        fieldName: "denominations_comments" | "floats_comments" | "variance_comments"
    ) => {
        const tableDataDeepCopy = cloneDeep(safeTableData);
        tableDataDeepCopy[rowIndex].safe_data[fieldName] = {
            content: comment,
            mentions: [],
        };

        setSafeTableData(tableDataDeepCopy);
    };

    const onCommentClear = (
        rowIndex: number,
        fieldName: "denominations_comments" | "floats_comments" | "variance_comments"
    ) => {
        const tableDataDeepCopy = cloneDeep(safeTableData);
        tableDataDeepCopy[rowIndex].safe_data[fieldName] = {
            content: "",
            mentions: [],
        };

        setSafeTableData(tableDataDeepCopy);
        syncTableDataStateDebounced(rowIndex, tableDataDeepCopy);
    };

    const onCommentSubmit = (rowIndex: number) => {
        syncTableDataStateDebounced(rowIndex, safeTableData);
    };
    const TotalSum = useMemo(() => {
        return safeTableData.map((tableData) => {
            const { total } = tableData.safe_data;

            return {
                total: total ?? 0,
                shiftType:
                    safeLocationDetailByCashupId[tableData.cashup_id].shiftType,
            };
        });
    }, [safeTableData]);

    const TableLength = useMemo(() => {
        return safeTableData.length;
    }, [safeTableData]);

    const submitted = useMemo(() => shiftStatus !== "UNSUBMITTED", [shiftStatus]);
    // TODO: Investigate
    const shouldShowLooseChange = useMemo(() => {
        return true;
    }, []);

    const PanelHelper = useCallback(
        (
            name: string,
            panelId: string,
            currentTableInFocus: string | undefined,
            currentSection: string,
            setCurrenTableInFocus?: (tableID?: string) => void
        ): JSX.Element => {
            switch (currentSection) {
                case "Denominations":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <DenominationsPanel
                                key={name}
                                name={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                tableData={safeTableData}
                                onTableDataChange={(tableData, index) =>
                                    onRowDataChange(index, tableData)
                                }
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                                disabled={submitted}
                                shouldShowLooseChange={shouldShowLooseChange}
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data
                                                .denominations_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .denominations_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(
                                        index,
                                        value,
                                        "denominations_comments"
                                    )
                                }
                                onCommentClear={(index) =>
                                    onCommentClear(index, "denominations_comments")
                                }
                                onCommentSubmit={(index) => onCommentSubmit(index)}
                                tableLength={TableLength}
                            />
                        </CollapsibleTable>
                    );

                case "Floats":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <FloatsPanel
                                key={name}
                                name={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                // @ts-ignore
                                floatLocations={floatLocations}
                                tableData={safeTableData}
                                onTableDataChange={(tableData, index) =>
                                    onRowDataChange(index, tableData)
                                }
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                                disabled={submitted}
                            />
                            <TransferModalComponent
                                // @ts-ignore
                                locations={
                                    safeLocations.filter(
                                        (sL: ExtendedLocationItemWithChildren) =>
                                            sL.location_type === 6
                                    )[0]
                                }
                                hierarchicalLocations={[
                                    ...safeLocations.filter(
                                        (sL: ExtendedLocationItemWithChildren) =>
                                            sL.location_type === 7 ||
                                            sL.location_type === 14 ||
                                            sL.location_type === 6
                                    ),
                                ]}
                                tableData={safeTableData}
                                onSubmit={onTransferTransactionSubmissionDebounce}
                                onOpen={() => setCurrenTableInFocus?.("TRANSFERS")}
                                accountsData={accounts}
                                classesData={classes}
                                onPaymentTransactionSubmission={(data, rowIndex) =>
                                    onPaymentSubmissionDebounce(data, rowIndex)
                                }
                                onPaymentTransactionRemove={(data, rowIndex) =>
                                    onPaymentTransactionRemove(
                                        data,
                                        rowIndex,
                                        accounts,
                                        safeTableData,
                                        onRowDataChange
                                    )
                                }
                                disabled={submitted}
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data.floats_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .floats_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(index, value, "floats_comments")
                                }
                                onCommentClear={(index) =>
                                    onCommentClear(index, "floats_comments")
                                }
                                onCommentSubmit={(index) => onCommentSubmit(index)}
                                tableLength={TableLength}
                            />
                        </CollapsibleTable>
                    );

                case "Total, Budget & Variance":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <TotalBudgetsVarianceTable
                                key={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                tableData={safeTableData}
                                totals={TotalSum}
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data.variance_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .variance_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                              undefined || submitted,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(
                                        index,
                                        value,
                                        "variance_comments"
                                    )
                                }
                                onCommentClear={(index) =>
                                    onCommentClear(index, "variance_comments")
                                }
                                onCommentSubmit={(index) => onCommentSubmit(index)}
                                tableLength={TableLength}
                            />
                            <ApprovedBySection
                                tableData={safeTableData}
                                users={users ? users : []}
                                tableLength={TableLength}
                            />
                            <ApproveButtonSection
                                onApprove={onSignOffCashupCount}
                                tableData={safeTableData}
                                tableLength={TableLength}
                                disabled={submitted}
                            />
                        </CollapsibleTable>
                    );

                default:
                    return <div></div>;
            }
        },
        [
            safeTableData,
            TableLength,
            safeLocationDetailByCashupId,
            submitted,
            shouldShowLooseChange,
            floatLocations,

            onTransferTransactionSubmissionDebounce,

            TotalSum,
            onSignOffCashupCount,
            onRowDataChange,
            onCommentChange,
            onPaymentSubmissionDebounce,
        ]
    );

    if (
        isLoading ||
        safeTableData.length !== sortedSafeCashupIDS.length ||
        safeLocations === undefined ||
        usersLoading
    )
        return <QuantacoLoader size={100} />;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {ExampleSections.map((name, index) => {
                const panelId = `${formattedDate}-${index}`;

                return PanelHelper(
                    name,
                    panelId,
                    currentTableInFocus,
                    name,
                    setCurrenTableInFocus
                );
            })}
        </Space>
    );
};

export const SafeTab = CashupTabHOC(SafeTabComponent);

import React, { useCallback, useMemo } from "react";
import { usePushCashupMutation } from "../../Redux/StateSlices/Pusher";
import {
    CashupSubLocationItem,
    ExtendedVenueItem,
} from "../../Redux/StateSlices/GroupData/VenuesAPI";
import {
    ClassTypes,
    InitDataResponseType,
    PosTotalData,
    TenderTypes,
} from "../../Pages/CashupHome/CashupTabs/POS/@types";
import {
    AggregateTransactions,
    generatedCashTenderItem,
    generatedDefaultClassSplitItems,
    generatedDefaultTenderSplitItems,
    onDepositInTransactionSubmission,
    onDepositOutTransactionSubmission,
    onEftposCountTransactionSubmission,
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { ExtendedClassItem } from "../../Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedAccountItem } from "../../Redux/StateSlices/GroupData/AccountsAPI";
import { TransactionsFormPOS } from "../../Pages/CashupHome/ExcelTable/TransactionsFormPOS";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import produce from "immer";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import {
    calculateEftposImbalanceFlag,
    countTransactions,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
    splitImbalanceCheck,
    statusHelper,
    transformLocationsToLocationDetailByCashup,
} from "../../utils/utilities";
import { Table } from "V2/Table/Table";
import { TableConfigs } from "V2/Table/TableHeader";
import set from "lodash/set";
import { TableRows } from "V2/Table/TableBody";
import { formatDollarField } from "@Constants";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import {
    AntDFormState,
    AntDFormStateEftposCountOnly,
    AntDFormStateWithoutSplit,
    AntDFormStateWithSplitFlag,
} from "@types";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { Checkbox, Divider } from "antd";
import styled from "styled-components";
import { prepareStringToNumber } from "./POSTotal";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { useVenueSelection } from "Context/VenueSelectionContextConstants";
import { useLoader } from "hooks/loaderProvider";
import { getPosIntegrationData } from "ApiV2/Helpers/getPosIntegrationData";
import {
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
} from "Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "hooks/useModal";
import dayjs from "dayjs";

export type TableDataType = InitDataResponseType;

const StyledDivider = styled(Divider)`
    margin: 0 0 10px 0;
`;
export const parseTableState = (
    currentTableState: TableDataType,
    manuallyChangedData?: boolean
) => {
    const tableState = {
        gaming_data: currentTableState.gaming_data,
        deposit_in_transactions: currentTableState.deposit_in_transactions,
        payment_transactions: currentTableState.payment_transactions,
        atm_data: currentTableState.atm_data,
        deposit_out_transactions: currentTableState.deposit_out_transactions,
        float_data: currentTableState.float_data,
        tab_keno_data: currentTableState.tab_keno_data,
        transfer_transactions: currentTableState.transfer_transactions,
        sales_count_transactions: currentTableState.sales_count_transactions,
        eftpos_count_transactions: currentTableState.eftpos_count_transactions,
        pos_data: currentTableState.pos_data,
        cashup_id: currentTableState.cashup_id,
        cash_count: currentTableState.cash_count,
    };

    if (manuallyChangedData) {
        Object.assign(tableState, { user_edited_pos_data: true });
    }
    return tableState;
};

const initializePOSFields = (cashup: InitDataResponseType): InitDataResponseType => {
    const posData = cashup.pos_data;
    return {
        ...cashup,
        pos_data: {
            amount: posData.amount ?? 0,
            account_split: posData.account_split,
            tax_amount: posData.tax_amount,
            class_split: posData.class_split,
        },
    };
};

interface Props extends PanelHOCComponentProps {
    classesData: ExtendedClassItem[];
    accountsData: ExtendedAccountItem[];
    subLocations: CashupSubLocationItem[];
    locations: ExtendedLocationItem[];
    transferLocations: ExtendedLocationItemWithChildren[];
    venuesData?: ExtendedVenueItem[];
    posData?: PosTotalData;
    submitted: boolean;
    posintegrationFlag: boolean;
    mutate: ({
        venueId,
        selectedDate,
    }: {
        venueId: string;
        selectedDate: string;
    }) => void;
    setLastTransaction: React.Dispatch<React.SetStateAction<string>>;
}

export const getSalesTotal = (
    tableData: InitDataResponseType[],
    row: number,
    field: "pos_data" | "tab_keno_data" = "pos_data"
): number => {
    if (!tableData[row]) {
        return 0;
    }
    if (tableData[row]?.[field]?.amount) {
        return tableData[row][field].amount ?? 0;
    }
    return 0;
};

export const initialiseColumnNames = (
    accountsData: ExtendedAccountItem[],
    classesData: ExtendedClassItem[],
    betweenTenderAndClass?: {
        columnTitle: string;
        separateColumn?: boolean;
        hideTotal?: boolean;
    },
    tenderOnly = false
): TableConfigs => {
    const columnTitles: TableConfigs = [];
    !tenderOnly &&
        classesData.forEach((currentClass) => {
            const normalisedString = currentClass.name.toLowerCase().trim();
            // Note: In future instead of doing a strict string check an array of IDs will be passed to specify which Class Items need to be their own column and which should be in the "Others" section
            if (normalisedString === "food") {
                columnTitles.push({
                    columnTitle: "Food",
                });
            } else if (normalisedString === "beverage") {
                columnTitles.push({
                    columnTitle: "Beverage",
                });
            }
        });
    !tenderOnly &&
        columnTitles.push({
            columnTitle: "Other classes",
            separateColumn: betweenTenderAndClass ? false : true,
        });

    if (betweenTenderAndClass) {
        columnTitles.push(betweenTenderAndClass);
    }

    accountsData
        .filter(
            (currentAccount) =>
                currentAccount.tender_type === true ||
                currentAccount.name.toLowerCase().trim() === "eftpos"
        )
        .forEach((currentAccount) => {
            const normalisedString = currentAccount.name.toLowerCase().trim();

            if (normalisedString === "eftpos") {
                columnTitles.push({
                    columnTitle: "Eftpos",
                });
            } else if (normalisedString === "visa") {
                columnTitles.push({ columnTitle: "Visa" });
            }
        });
    columnTitles.push({
        columnTitle: "Other tenders",
    });

    return columnTitles;
};

export const initialiseCashColumn = (
    accountsData: ExtendedAccountItem[]
): TableConfigs => {
    const cashColumn: TableConfigs = [];
    const cashAccount = accountsData.find(
        (currentAccount) =>
            currentAccount.tender_type === true &&
            currentAccount.name.toLowerCase().trim() === "cash"
    );

    if (cashAccount !== undefined) {
        cashColumn.push({
            columnTitle: "Cash",
        });
    }
    return cashColumn;
};

export const CollapsePanelPOS: React.FC<Props> = ({
    classesData,
    accountsData,
    subLocations,
    locations,
    transferLocations,
    venuesData,
    onClickHandler,
    isInFocus,
    posData,
    submitted,
    posintegrationFlag,
    mutate,
    setLastTransaction,
}) => {
    const [pushTableState] = usePushCashupMutation();

    const { venueId, shiftDate } = useVenueSelection();

    const locationDetailByCashupId = useMemo(
        () => transformLocationsToLocationDetailByCashup(subLocations),
        [subLocations]
    );

    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) => {
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);
                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });
    };

    const cashupIds = useMemo(
        () =>
            subLocations
                .flatMap(({ cashups }) => cashups.map(({ cashup_id }) => cashup_id))
                .sort((cashupA_ID, cashupB_ID) =>
                    sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
                ),
        [subLocations, locationDetailByCashupId]
    );
    const { isLoading } = useCashupRealtime({
        cashupIds,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const pushDataStateDebounce = useDebouncedCallback(pushTableState, 0);

    const pushCurrentTableState = useCallback(
        (currentTableRow: TableDataType, manuallyChangedData?: boolean) => {
            return pushDataStateDebounce(
                parseTableState(currentTableRow, manuallyChangedData)
            );
        },
        [pushTableState]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = React.useCallback(
        (manuallyChangedData?: boolean) =>
            (rowIndex: number, tableData: TableDataType[]) => {
                pushCurrentTableState(tableData[rowIndex], manuallyChangedData);
            },
        [pushCurrentTableState]
    );

    const onPaymentSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormState[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                tableData,
                onRowDataChange()
            ),
        300
    );

    const onDepositInTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithSplitFlag[], rowIndex: number) =>
            onDepositInTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange()
            ),
        300
    );
    const onDepositOutTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithSplitFlag[], rowIndex: number) =>
            onDepositOutTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange()
            ),
        300
    );

    const onEftposCountTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateEftposCountOnly[], rowIndex: number) =>
            onEftposCountTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange(),
                accountsData,
                classesData
            ),
        300
    );

    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange()
            ),
        300
    );
    const { withLoader } = useLoader();
    const { openModal, closeModal } = useContextModal();

    const posIntegration = useCallback(async () => {
        withLoader(async () => {
            await getPosIntegrationData(venueId, shiftDate)
                .then((data) => {
                    setLastTransaction(
                        data?.pos_last_updated_transaction
                            ? dayjs(
                                  data.pos_last_updated_transaction.date_time
                              ).format("ddd DD-MM-YYYY hh:mm")
                            : ""
                    );
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Updated successfully"
                    );
                })
                .catch((error) => {
                    setLastTransaction("");
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        undefined,
                        <>
                            <h3>Update failed</h3>
                            <p>
                                {typeof error == "string"
                                    ? error
                                    : typeof error == "object"
                                    ? error.status
                                    : ""}
                            </p>
                        </>,
                        5000
                    );
                });
        });
    }, [venueId, shiftDate]);

    const dataSources: TableRows[] = useMemo(
        () =>
            tableData.map((cashup, rowIndex) => {
                const {
                    cashup_id,
                    cash_count,
                    payment_transactions,
                    deposit_in_transactions,
                    deposit_out_transactions,
                    transfer_transactions,
                    transfer_transactions_to,
                    eftpos_count_transactions,
                    eftpos_balance,
                    status,
                    float_value,
                    total_variance,
                    tender_total,
                    sales_total,
                    user_edited_pos_data,
                } = cashup;

                const imbalanceFlag = splitImbalanceCheck({
                    statusField: status,
                    tenderTotal: tender_total,
                    salesTotal: sales_total,
                });

                const eftposImbalanceFlag = calculateEftposImbalanceFlag(
                    rowIndex,
                    tableData,
                    eftpos_balance ?? 0
                );

                const transactionsAggregateValue = AggregateTransactions(
                    accountsData,
                    rowIndex,
                    tableData
                );

                return [
                    {
                        value: locationDetailByCashupId[cashup_id].name,
                        readOnly: true,
                        subValue: parseTimePeriod(
                            locationDetailByCashupId[cashup_id].timePeriod
                        ),
                        manuallyChangedData: user_edited_pos_data ?? false,
                        editPopover: (
                            <>
                                <StyledDivider />
                                {submitted ? (
                                    <span>Pos data has been manually edited</span>
                                ) : (
                                    <span>
                                        Pos data has been manually edited, cancel
                                        user change and reload data from remote?
                                    </span>
                                )}
                                <Checkbox
                                    style={{ float: "right" }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            onRowDataChange(false)(
                                                rowIndex,
                                                tableData
                                            );
                                            posIntegration();
                                        }
                                    }}
                                    disabled={submitted}
                                >
                                    Yes
                                </Checkbox>
                            </>
                        ),
                    },
                    {
                        value: float_value ?? 0,
                        readOnly: true,
                    },

                    ...generatedDefaultClassSplitItems(
                        tableData,
                        onRowDataChange(posintegrationFlag),
                        classesData,
                        rowIndex,
                        imbalanceFlag,
                        submitted
                    ),

                    {
                        modalTitle: "Sales total",
                        readOnly: true,
                        value: sales_total,
                    },
                    ...generatedDefaultTenderSplitItems(
                        tableData,
                        onRowDataChange(posintegrationFlag),
                        accountsData,
                        rowIndex,
                        imbalanceFlag,
                        submitted
                    ),
                    ...generatedCashTenderItem(
                        tableData,
                        onRowDataChange(posintegrationFlag),
                        accountsData,
                        rowIndex,
                        imbalanceFlag,
                        submitted
                    ),
                    {
                        value: tender_total ?? 0,
                        readOnly: true,
                    },
                    {
                        modalTitle: "Transactions",
                        value:
                            countTransactions([
                                payment_transactions ?? [],
                                deposit_in_transactions ?? [],
                                deposit_out_transactions ?? [],
                                transfer_transactions ?? [],
                                // eftpos_count_transactions ?? [],
                                transfer_transactions_to ?? [],
                            ]) ?? ``,
                        totalValue: transactionsAggregateValue,
                        editModal: (closeModal) => (
                            <TransactionsFormPOS
                                key={
                                    "POS_" +
                                    locationDetailByCashupId[cashup_id].location_id
                                }
                                row={rowIndex}
                                currentLocationID={
                                    locationDetailByCashupId[cashup_id].location_id
                                }
                                onModalClose={closeModal}
                                accountsData={accountsData}
                                classesData={classesData}
                                onPaymentTransactionSubmission={(data) =>
                                    onPaymentSubmissionDebounce(data, rowIndex)
                                }
                                onPaymentTransactionRemove={(data) =>
                                    onPaymentTransactionRemove(
                                        data,
                                        rowIndex,
                                        accountsData,
                                        tableData,
                                        onRowDataChange()
                                    )
                                }
                                onDepositInTransactionSubmission={(data) =>
                                    onDepositInTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                onDepositOutTransactionSubmission={(data) =>
                                    onDepositOutTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                onEftposCountTransactionSubmission={(data) =>
                                    onEftposCountTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                PaymentTransactionData={payment_transactions ?? []}
                                DepositInTransactionData={
                                    deposit_in_transactions ?? []
                                }
                                DepositOutTransactionData={
                                    deposit_out_transactions ?? []
                                }
                                EftposCountTransactions={
                                    eftpos_count_transactions ?? []
                                }
                                EftposBalance={eftpos_balance ?? 0}
                                onTransferTransactionSubmission={(data) =>
                                    onTransferTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                locations={locations}
                                TransferTransactions={transfer_transactions ?? []}
                                TransferTransactionsReadOnly={
                                    transfer_transactions_to ?? []
                                }
                                //@ts-ignore
                                hierarchicalLocations={[...transferLocations]}
                                eftposErrorHighlighter={eftposImbalanceFlag}
                                disabled={submitted}
                            />
                        ),
                        hideEditModalFooter: true,
                    },
                    {
                        value:
                            typeof total_variance !== "string"
                                ? formatDollarField(total_variance ?? 0)
                                : total_variance,
                        readOnly: true,
                        statusField: () =>
                            status !== null
                                ? statusHelper({
                                      statusField: status,
                                  })
                                : null,
                    },
                    {
                        value: cash_count.actual ?? "$0",
                        applyDollarFormatting: true,
                        isExplicitlyNotTitleCell: true,
                        propertyPath: "cash_count.actual",
                        nanInsteadOfZeroOut: true,
                        readOnly: submitted,
                    },
                    {
                        value:
                            typeof cash_count.cash_variance !== "string"
                                ? formatDollarField(cash_count.cash_variance ?? 0)
                                : cash_count.cash_variance,
                        readOnly: true,
                        statusField: () =>
                            status !== null
                                ? statusHelper({
                                      statusField: status,
                                      //   classesAggValue: Number(classAggregateValue),
                                      //   tenderAggValue: Number(tenderAggregateValue),
                                      //   salesTotalValue: Number(salesTotalValue),
                                      //   splitErrorType: "NONE", // Hard coded to none for now
                                      //   //   imbalanceFlag,
                                  })
                                : null,
                    },
                ];
            }),
        [
            tableData,
            accountsData,
            classesData,
            transferLocations,
            locationDetailByCashupId,
            locations,
            onDepositInTransactionSubmissionDebounce,
            onDepositOutTransactionSubmissionDebounce,
            onEftposCountTransactionSubmissionDebounce,
            onPaymentSubmissionDebounce,
            onRowDataChange,
            onTransferTransactionSubmissionDebounce,
            venuesData,
            submitted,
            posintegrationFlag,
        ]
    );

    const tableConfigs: TableConfigs = [
        {
            columnTitle: "Till",
        },
        {
            columnTitle: "Float",
        },

        ...initialiseColumnNames(accountsData, classesData, {
            columnTitle: "Sales total",
            separateColumn: true,
        }),
        ...initialiseCashColumn(accountsData),
        {
            columnTitle: "Tenders total",
        },

        {
            columnTitle: "Transactions",
        },
        {
            columnTitle: "Total variance",
            separateColumn: true,
        },
        {
            columnTitle: "Cash count",
        },
        {
            columnTitle: "Cash variance",
        },
    ];

    const generateTotalForClassSplitItems = useCallback(
        (classesData: ExtendedClassItem[], posTotal?: PosTotalData) => {
            const ClassSplitColumns: TableRows = [];
            classesData
                .filter(
                    (currentClassItem) => currentClassItem.name !== ClassTypes.GAMING
                )
                .forEach((currentClassItem) => {
                    if (currentClassItem.name === ClassTypes.FOOD) {
                        ClassSplitColumns.push({
                            value: prepareStringToNumber(posTotal!.class_split.Food),
                            readOnly: true,
                        });
                    } else if (currentClassItem.name === ClassTypes.BEVERAGE) {
                        ClassSplitColumns.push({
                            value: prepareStringToNumber(
                                posTotal!.class_split.Beverage
                            ),
                            readOnly: true,
                        });
                    }
                });
            ClassSplitColumns.push({
                value: prepareStringToNumber(posTotal!.class_split.Other),
                readOnly: true,
            });
            return ClassSplitColumns;
        },
        []
    );

    const generateTotalForTenderSplitItems = useCallback(
        (accountsData: ExtendedAccountItem[], posTotal?: PosTotalData) => {
            const TenderSplitColumns: TableRows = [];
            accountsData
                .filter(
                    (currentTenderSplitItem) =>
                        currentTenderSplitItem.tender_type === true ||
                        currentTenderSplitItem.name === TenderTypes.EFTPOS
                )
                .forEach((currentTenderSplitItem) => {
                    if (currentTenderSplitItem.name === TenderTypes.EFTPOS) {
                        TenderSplitColumns.push({
                            value: prepareStringToNumber(
                                posTotal!.account_split.Eftpos
                            ),
                            readOnly: true,
                        });
                    } else if (currentTenderSplitItem.name == TenderTypes.VISA) {
                        TenderSplitColumns.push({
                            value: prepareStringToNumber(
                                posTotal!.account_split.Visa
                            ),
                            readOnly: true,
                        });
                    }
                });
            TenderSplitColumns.push({
                value: prepareStringToNumber(posTotal!.account_split.Other),
                readOnly: true,
            });
            const cashTenderItem = accountsData.find(
                (currentTenderSplitItem) =>
                    currentTenderSplitItem.tender_type === true &&
                    currentTenderSplitItem.name === TenderTypes.CASH
            );
            if (cashTenderItem !== undefined) {
                TenderSplitColumns.push({
                    value: prepareStringToNumber(posTotal!.account_split.Cash),
                    readOnly: true,
                });
            }
            return TenderSplitColumns;
        },
        []
    );

    const totalRow: TableRows = useMemo(
        () =>
            posData
                ? [
                      {
                          value: "Total",
                          readOnly: true,
                      },
                      {
                          value: posData.location_float_value,
                          readOnly: true,
                          isExplicitlyNotTitleCell: true,
                      },
                      ...generateTotalForClassSplitItems(classesData, posData),
                      {
                          value: Number(posData.sales_total),
                          readOnly: true,
                          isExplicitlyNotTitleCell: true,
                      },
                      ...generateTotalForTenderSplitItems(accountsData, posData),
                      {
                          value: Number(posData.tender_total),
                          readOnly: true,
                          isExplicitlyNotTitleCell: true,
                      },
                      {
                          value: Number(posData.transactions),
                          readOnly: true,
                      },
                      {
                          value: Number(posData.total_variance),
                          readOnly: true,
                          statusField: () => null,
                      },
                      {
                          value: Number(posData.cash_count_actual),
                          readOnly: true,
                          isExplicitlyNotTitleCell: true,
                      },
                      {
                          value: Number(posData.cash_count_cash_variance),
                          readOnly: true,
                          isExplicitlyNotTitleCell: true,
                      },
                  ]
                : [],
        [posData, generateTotalForClassSplitItems, generateTotalForTenderSplitItems]
    );
    return (
        <>
            {isLoading || tableData.length === 0 ? (
                <CentredSpinner
                    style={{
                        marginTop: "-35px",
                        position: "absolute",
                        textAlign: "center",
                        left: "50%",
                    }}
                    size={"small"}
                />
            ) : (
                <Table
                    onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                        const updatedCashup = produce(
                            initializePOSFields(tableData[row]),
                            (draft) => {
                                set(draft, propertyPath, updatedValue);
                            }
                        );
                        pushCurrentTableState(updatedCashup);
                    }}
                    dataSources={dataSources}
                    tableConfigs={tableConfigs}
                    onClickHandler={onClickHandler}
                    isInFocus={isInFocus}
                    totalRow={totalRow}
                    totalRowFromBackend
                />
            )}
        </>
    );
};
